import { process } from "../../env";

export function fetchRegistrationTasks() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration/tasks`;
  return fetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`,
    },
  })
    .then((response) => response.json());
}

export function fetchRegistrationPayments() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/registration/payments`;
  return fetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`,
    },
  })
    .then((response) => response.json());
}