import { process } from '../../env';
import { ResponseInterface } from './index';
import { ApiError } from './ApiError';

export function createProductSpecification(product: string) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/products`;
  return fetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.token}`,
    },
    body: JSON.stringify(product),
  })
    .then((response) => response.json());
}

export function fetchProductSpecificationById(productSpecificationId: string) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/products/${productSpecificationId}`;
  return fetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.token}`,
    },
  })
    .then((response) => response.json());
}

export function fetchOwnProductSpecifications() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/products`;
  return fetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.token}`,
    },
  })
    .then((response) => response.json());
}

export function fetchMultiTicketComponents() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/products/multi-ticket-components`;
  return fetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.token}`,
    },
  })
    .then((response) => response.json());
}

export function findProductSpecifications(searchQuery: string): Promise<ResponseInterface> {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/products/search/${encodeURIComponent(searchQuery)}`;
  return fetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.token}`,
    },
  })
    .then((response) => response.json());
}

export function updateProductSpecificationById(productSpecificationId: string, product: any) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/products/${productSpecificationId}`;
  return fetch(requestURL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.token}`,
    },
    body: JSON.stringify(product),
  })
    .then((response) => response.json());
}

export async function addProductPhoto(photo: any) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/products/photo`;
  const formData = new FormData();
  formData.append('file', photo);
  const backendResponse = await fetch(requestURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${window.localStorage.token}`,
    },
    body: formData,
  });
  if (backendResponse.ok) {
    return backendResponse.json();
  }
  throw new ApiError(backendResponse);
}
