import * as React from 'react';
import { Cascader as _Cascader, CascaderProps as _CascaderProps } from 'antd';
import type { BaseOptionType } from 'antd/lib/cascader';
import { FieldProps } from 'formik';
import { CascaderRef } from 'antd/lib/cascader';
import { FormikFieldProps } from './FieldProps';
import Field from './Field';
import { makeField } from './makeField';

type CascaderProps<T extends BaseOptionType = any> = FieldProps & _CascaderProps<T>;
type CascaderFieldProps<T extends BaseOptionType = any> = FormikFieldProps & _CascaderProps<T>;

const Cascader = React.forwardRef((
  {
    field,
    form,
    meta,
    onChange,
    ...restProps
  }: CascaderProps<any>,
  ref: React.Ref<CascaderRef>,
) => {
  const { value, name } = field;
  const { setFieldValue, setFieldTouched } = form;
  return (
    <_Cascader
      ref={ref}
      value={value as any}
      onChange={(selectedValue: any, options: any) => {
        setFieldValue(name, selectedValue);
        setFieldTouched(name, true, false);
        if (onChange) {
          onChange(selectedValue, options);
        }
      }}
      {...restProps}
    />
  );
});

Cascader.displayName = 'Cascader';

export const CascaderField = React.forwardRef((
  {
    name,
    validate,
    fast,
    ...restProps
  }: CascaderFieldProps<any>,
  ref: React.Ref<CascaderRef>,
) => (
  <Field name={name} validate={validate} fast={fast}>
    {(fieldProps: any) => (
      <Cascader ref={ref} {...fieldProps} {...restProps} />
    )}
  </Field>
));
CascaderField.displayName = 'CascaderField';

export const CascaderWrapper = makeField<_CascaderProps<any>>(Cascader);
