import React from 'react';
import { Helmet } from 'react-helmet';
import { Button, Checkbox, Dropdown, Modal, Space, Table, Typography } from 'antd';
import { CopyOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { RbacManagerInterface } from '@brainstaff/rbac';

import './ProductsPage.scss';

import { ApplicationLayout } from '../application/layouts/application-layout/ApplicationLayout';
import { SchemaType, SchemaTypeLabel } from '../../constants/schema';
import { ProductFormModal } from './product-form-modal/ProductFormModal';

import { ProductGroupFormModalContainer } from './product-group-form/ProductGroupFormModalContainer';
import { Role } from '../../constants/rbac';
import { ProductShareModal } from './product-share-modal/ProductShareModal';

export interface ProductSpecificationInterface {
  id: string
  accountId: string
  productGroupId: string,
  specification: any
  active: boolean
  inactivityReason: string
}

interface ProductGroupInterface {
  id: string
  name: string
}

interface ProductsPageProps {
  products: ProductSpecificationInterface[],
  productGroups: ProductGroupInterface[]
  accountId: string,
  rbacManager: RbacManagerInterface,
  user: any,
  onLoadProducts: () => {},
  onUpdateProduct: (productSpecificationId: string, productSpecificationDto: any) => {},
  onLoadProduct: (productSpecificationId: string) => Promise<{ product: ProductSpecificationInterface }>,
  onDeleteProductGroup: (productGroupId: string) => {},
}

interface ProductsPageState {
  productModalVisible: boolean
  productShareModalVisible: boolean
  product: ProductSpecificationInterface | null
  groupId: string | null
  schemaType: string
  productGroupModalVisible: boolean,
  productGroup: object | null,
  isMutiTicketManager: boolean,
}

export class ProductsPage extends React.Component<ProductsPageProps, ProductsPageState> {
  constructor(props: ProductsPageProps) {
    super(props);
    this.state = {
      productModalVisible: false,
      productShareModalVisible: false,
      product: null,
      groupId: null,
      schemaType: SchemaType.BaseCalendar,
      productGroupModalVisible: false,
      productGroup: null,
      isMutiTicketManager: false,
    };
  }

  componentDidMount() {
    const { rbacManager, user, onLoadProducts } = this.props;
    onLoadProducts();
    rbacManager.checkAccess(user.id, Role.MultiticketManager)
      .then((isMutiTicketManager: boolean) => {
        this.setState({ isMutiTicketManager });
      });
  }

  showProductGroupDeleteConfirm(productGroupId: string) {
    const page = this;
    const { onDeleteProductGroup } = this.props;
    Modal.confirm({
      title: 'Вы точно хотите удалить группу?',
      icon: <ExclamationCircleOutlined />,
      content: 'Товары группы потеряют с ней связь, но не будут удалены.',
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Отмена',
      async onOk() {
        page.setState({ groupId: null });
        const result = await onDeleteProductGroup(productGroupId);
        return result;
      },
    });
  }

  render() {
    const { products, productGroups, accountId, onUpdateProduct } = this.props;
    const { productModalVisible, productShareModalVisible, groupId } = this.state;
    const { productGroupModalVisible, productGroup } = this.state;
    const { isMutiTicketManager } = this.state;

    const productsToShow = products.filter((product) => product.productGroupId === groupId);

    const columns = [
      {
        title: 'Название',
        dataIndex: [ 'specification', 'title' ],
      }, {
        title: 'Тип',
        dataIndex: [ 'specification', 'type' ],
        width: 220,
        render: (type: string) => SchemaTypeLabel[type],
      }, {
        title: 'Активность',
        dataIndex: [ 'active' ],
        width: 120,
        render: (active: boolean, row: any) => (
          <Checkbox
            checked={active}
            onChange={(v) => {
              onUpdateProduct(row.id, { ...row, active: v.target.checked });
            }}
          />
        ),
      }, {
        title: 'Цена',
        dataIndex: [ 'specification' ],
        width: '150px',
        render: (specification: any) => {
          if (specification.type === SchemaType.Plain) {
            return specification.price;
          }
          if (
            [
              SchemaType.Base,
              SchemaType.BaseCalendar,
              SchemaType.MultiTicketComponent,
            ].includes(specification.type)
          ) {
            return specification.categories.map((category: any) => category.price).join(' / ');
          }
          return null;
        },
      }, {
        title: 'Действия',
        dataIndex: 'id',
        width: '80px',
        render: (id: string, record: ProductSpecificationInterface) => (
          <Space>
            <Button
              icon={<EditOutlined />}
              onClick={async () => {
                const { onLoadProduct } = this.props;
                const { product } = await onLoadProduct(id);
                this.setState({
                  productModalVisible: true,
                  product,
                  schemaType: product.specification.type,
                });
              }}
            />
            {
              (record.specification.type === SchemaType.BaseCalendar || record.specification.type === SchemaType.MultiTicketCalendar) && (
                <Button
                  icon={<CopyOutlined />}
                  onClick={async () => {
                    const { onLoadProduct } = this.props;
                    const { product } = await onLoadProduct(id);
                    this.setState({
                      productShareModalVisible: true,
                      product,
                    });
                  }}
                />
              )
            }
          </Space>
        ),
      },
    ];

    const { product, schemaType } = this.state;

    const addProductItems = [
      // { label: 'Простой', key: SchemaType.Plain },
      // { label: 'Базовый', key: SchemaType.Base },
      { label: 'Базовый с календарем', key: SchemaType.BaseCalendar },
      // { label: 'Компонент Единого Билета', key: SchemaType.MultiTicketComponent },
    ];

    if (isMutiTicketManager) {
      addProductItems.push({ label: 'Единый Билет', key: SchemaType.MultiTicketCalendar });
      // addProductItems.push({ label: 'Единый Билет v1', key: SchemaType.MultiTicket, });
      // addProductItems.push({ label: 'Единый Билет v1 (Компонент)', key: SchemaType.MultiTicketComponent });
    }

    // @ts-ignore
    // @ts-ignore
    return (
      <ApplicationLayout>
        <Helmet>
          <title>Товары</title>
        </Helmet>
        <div className="ProductsPage">
          <div className="ProductsPage-menu">
            <div className="ProductsPage-menuItems">
              <Typography.Title level={3}>Группы</Typography.Title>
              <div className="ProductsPage-menuItem" onClick={() => this.setState({ groupId: null })}>
                {groupId === null ? <b>Без группы</b> : 'Без группы'}
              </div>
              {
                productGroups.map((productGroup: ProductGroupInterface) => (
                  <div
                    key={productGroup.id}
                    className="ProductsPage-menuItem"
                    onClick={() => this.setState({ groupId: productGroup.id, productGroup: productGroup })}
                  >
                    {
                      groupId === productGroup.id
                        ? <div><b>{productGroup.name}</b></div>
                        : productGroup.name
                    }
                  </div>
                ))
              }
            </div>
            <div className="ProductsPage-menuButtons">
              {
                groupId
                && (
                  <Button onClick={() => {
                    this.setState({
                      productGroupModalVisible: true,
                      productGroup,
                    });
                  }}
                  >
                    Изменить
                  </Button>
                )
              }
              {
                groupId
                && (
                  <Button onClick={() => {
                    this.showProductGroupDeleteConfirm(groupId);
                  }}
                  >
                    Удалить
                  </Button>
                )
              }
              <Button
                onClick={() => {
                  this.setState({
                    productGroupModalVisible: true,
                    productGroup: null,
                  });
                }}
              >
                Добавить
              </Button>
            </div>
          </div>
          <div className="ProductsPage-content">
            <div className="ProductsPage-header">
              <Typography.Title level={3} className="ProductsPage-header-title">Товары</Typography.Title>
              <div className="ProductsPage-headerButtons">
                <Dropdown
                  placement="bottomRight"
                  menu={{
                    onClick: ({ key }) => {
                      this.setState({
                        productModalVisible: true,
                        product: null,
                        schemaType: key,
                      });
                    },
                    items: addProductItems,
                  }}
                >
                  <Button className="ProductsPage-header-addProductBtn">
                    Добавить товар
                  </Button>
                </Dropdown>
              </div>
            </div>
            <Table
              columns={columns}
              /** @ts-ignore */
              rowClassName={(record) => {
                if (!record.active && record.inactivityReason) {
                  return 'ProductsPage-tableRow--inactive';
                }
                return undefined;
              }}
              dataSource={productsToShow}
              pagination={false}
              rowKey="id"
              expandable={{
                expandedRowRender: (record) => (
                  <div>
                    <div>
                      ID:
                      {record.id}
                    </div>
                    <div>
                      {record.inactivityReason && `Причина неактивности: ${record.inactivityReason}`}
                    </div>
                  </div>
                ),
              }}
            />
            {
              productModalVisible && (
                <ProductFormModal
                  open
                  schemaType={schemaType}
                  product={product}
                  onCancel={() => {
                    this.setState({ productModalVisible: false });
                  }}
                  onComplete={() => {
                    this.setState({ productModalVisible: false });
                  }}
                />
              )
            }
            {
              productShareModalVisible && product && (
                <ProductShareModal
                  open
                  product={product}
                  distributorId={accountId}
                  onComplete={() => {
                    this.setState({ productShareModalVisible: false });
                  }}
                />
              )
            }
            {
              productGroupModalVisible && (
                <ProductGroupFormModalContainer
                  // @ts-ignore
                  open
                  productGroup={productGroup}
                  onCancel={() => {
                    this.setState({ productGroupModalVisible: false });
                  }}
                  onComplete={() => {
                    this.setState({ productGroupModalVisible: false });
                  }}
                />
              )
            }
          </div>
        </div>
      </ApplicationLayout>
    );
  }
}