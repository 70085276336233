import { connect } from 'react-redux';

import { checkProductItem, consumeProductItem } from "../../actions/productsActions";
import { currentUserSelector } from "../../selectors/currentUserSelectors";

import ScannerPage from "./ScannerPage";

const mapStateToProps = (state) => ({
  accountId: currentUserSelector(state).accountId
});

const mapDispatchToProps = (dispatch) => ({
  onRemoteProductItemCheck: (productItem) => {
    return new Promise((resolve, reject) => {
      dispatch(checkProductItem(productItem, resolve, reject));
    });
  },
  onRemoteProductItemConsume: (productItemId, productSpecificationId) => {
    return new Promise((resolve, reject) => {
      dispatch(consumeProductItem(productItemId, productSpecificationId, resolve, reject));
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ScannerPage);
