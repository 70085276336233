export const PublicRoutes = {
  SignUp: '/signup',
  Restore: '/restore',
  PaymentMock: '/mock/tinkoff/pay/:PaymentId',
};

export const PrivateRoutes = {
  Products: '/products',
  Orders: '/orders',
  Purchases: '/purchases',
  Scanner: '/scanner',
  Partners: '/partners/:tab',
  Inspectors: '/inspectors',
  Account: '/account/:tab',
  Reports: '/reports',
  PaymentMock: '/mock/tinkoff/pay/:PaymentId',
  Balance: '/balance',
  BalanceInfo: '/balance/balance',
  BalanceBankDetails: '/balance/bank-details',
};
