import { takeEvery, call, put } from 'redux-saga/effects';

import { FETCH_SHOPS } from "../actions/shopsActions";
import { fetchShops } from './api';

// Saga workers

export function* fetchShopsWorker(action) {
    const response = yield call(fetchShops);
    if (response.status === 'OK') {
        action?.meta?.resolve(response.payload.shops);
        return response.payload.shops;
    } else if (response.status === 'ERROR') {
        action?.meta?.reject();
        return null;
    }
}


// Saga watchers

export default function* users() {
    yield takeEvery(FETCH_SHOPS, fetchShopsWorker);
}