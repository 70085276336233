import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './Header.scss';
import { Role } from '../../../constants/rbac';

const Header = ({ rbacManager, user, onSignOut }) => {
  const [isAdministrator, setIsAdministrator] = useState(false);
  const [isOrganizer, setIsOrganizer] = useState(false);
  const [isInspector, setIsInspector] = useState(false);
  useEffect(() => {
    let isUnmounted = false;
    rbacManager.checkAccess(user.id, Role.Administrator)
      .then((isAdministrator) => {
        if (!isUnmounted) {
          setIsAdministrator(isAdministrator)
        }
      });
    rbacManager.checkAccess(user.id, Role.Organizer)
      .then((isOrganizer) => {
        if (!isUnmounted) {
          setIsOrganizer(isOrganizer)
        }
      });
    rbacManager.checkAccess(user.id, Role.Inspector)
      .then((isInspector) => {
        if (!isUnmounted) {
          setIsInspector(isInspector)
        }
      });
    return () => {
      isUnmounted = true;
    }
  }, []);

  return (
    <div className="Header">
      <div>
        <b className="title">ClickPass</b>
      </div>
      {
        user.id &&
        <span>
          <b>{user.email}</b>&nbsp;&nbsp;
          <small>{user.account.id}</small>&nbsp;&nbsp;
          <a onClick={onSignOut}>Выйти</a>
        </span>
      }
    </div>
  );
};

export default Header;
