import { process } from '../../env';
import { commonFetch, encodeQueryFilter, getResponse, Response } from './apiHelper';

export interface Location {
  id: string;
  region: string;
  municipality: string;
  settlement: string;
  type: string;
  latitude_dms: string;
  longitude_dms: string;
  latitude_dd: string;
  longitude_dd: string;
  extern_id: number;
}

export const searchLocation = async (searchString: string): Promise<Location[]> => {
  const backendResponse = await commonFetch(
    `${process.env.BACKEND_URL}/api/v1/location${encodeQueryFilter({ search: searchString })}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const response: Response<Location[]> = await backendResponse.json();
  return getResponse(response) ?? [];
};

export const getLocation = async (locationId: string): Promise<Location | undefined> => {
  const backendResponse = await commonFetch(
    `${process.env.BACKEND_URL}/api/v1/location/${locationId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const response: Response<Location> = await backendResponse.json();
  return getResponse(response);
};
