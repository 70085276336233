import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FieldArray, FieldArrayRenderProps, getIn } from 'formik';
import { Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { addProductPhoto } from '../../../../../sagas/api';
import { process } from '../../../../../env';

interface Props {
  name: string;
}

interface DraggableUploadListItemProps {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  file: UploadFile<any>;
}

const DraggableUploadListItem = ({ originNode, file }: DraggableUploadListItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: file.uid,
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'move',
    height: '100%',
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      // prevent preview event when drag end
      className={isDragging ? 'is-dragging' : ''}
      {...attributes}
      {...listeners}
    >
      {/* hide error tooltip when dragging */}
      {file.status === 'error' && isDragging ? originNode.props.children : originNode}
    </div>
  );
};

export const InternalPhotos: React.FC<FieldArrayRenderProps & Props> = (props) => {
  const { form: { values }, name } = props;
  const photos = getIn(values, name, []);
  const [fileList, setFileList] = useState<UploadFile[]>(photos.map((photo: string) => ({
    uid: uuid(),
    name: photo,
    fileName: photo,
    status: 'done',
    url: `${process.env.BACKEND_URL}/api/public/v1/product/photo/${photo}?type=original`,
  })));

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id); // old
        const overIndex = prev.findIndex((i) => i.uid === over?.id); // new
        const { move } = props;
        move(activeIndex, overIndex);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  return (
    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
      <SortableContext items={fileList.map((i) => i.uid)} strategy={verticalListSortingStrategy}>
        <Upload
          accept="image/png, image/jpeg, image/gif, image/jpg"
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          onRemove={async (file) => {
            const { remove } = props;
            const fileIndex = photos.findIndex((photo: string) => photo === file.fileName);
            remove(fileIndex);
          }}
          customRequest={async ({ onSuccess, onError, file }) => {
            try {
              const { payload: { fileName } } = await addProductPhoto(file as Blob);
              // eslint-disable-next-line no-param-reassign
              (file as any).fileName = fileName;
              const { push } = props;
              push(fileName);
              if (onSuccess) {
                onSuccess(file);
              }
            } catch (error) {
              if (onError) {
                onError(error as Error);
              }
            }
          }}
          itemRender={(originNode, file) => (
            <DraggableUploadListItem originNode={originNode} file={file} />
          )}
        >
          + Загрузить
        </Upload>
      </SortableContext>
    </DndContext>
  );
};

export const Photos: React.FC<Props> = ({ name }) => (
  <FieldArray
    name={name}
    render={(arrayHelpers) => <InternalPhotos {...arrayHelpers} name={name} />}
  />
);

export default Photos;
