export const SchemaType = {
  // Deprecated
  Plain: 'plain',
  Base: 'base',
  MultiTicket: 'multi-ticket',
  MultiTicketComponent: 'multi-ticket-component',
  // Current
  BaseCalendar: 'base-calendar',
  MultiTicketCalendar: 'multi-ticket-calendar',
};

export const SchemaTypeLabel = {
  // Deprecated
  [SchemaType.Plain]: 'Простой',
  [SchemaType.Base]: 'Базовый',
  [SchemaType.MultiTicket]: 'Единый',
  [SchemaType.MultiTicketComponent]: 'Компонент Единого',
  // Current
  [SchemaType.BaseCalendar]: 'Базовый с календарем',
  [SchemaType.MultiTicketCalendar]: 'Единый с календарем',
};
