import { connect } from 'react-redux';

import { signIn } from "../../actions/authenticationActions";

import SigninPage from './SigninPage';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  onSignIn: (email, password, remember) => {
    return new Promise((resolve, reject) => {
      dispatch(signIn({ email, password, remember }, resolve, reject));
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SigninPage);
