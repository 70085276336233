import React, { useCallback } from 'react';
import { ModalProps, notification } from 'antd';
import { FormikHelpers } from 'formik';
import produce from 'immer';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { MultiTicketCalendarProductFormModal } from './MultiTicketCalendarProductFormModal';
import { productGroupsSelector } from '../../../../selectors/productsSelectors';
import { submitValidate } from '../../../../utils/form-helpers/antd-formik';
import { createProduct, updateProduct } from '../../../../actions/productsActions';
import { SchemaType } from '../../../../constants/schema';

import { multiTicketCalendarV1Schema, MultiTicketCalendarV1Type } from './multiTicketCalendarV1';

interface Props extends ModalProps {
  open?: boolean;
  onCancel: () => void;
  onComplete: () => void;
  product: any;
}

const defaultProduct: { specification: Partial<MultiTicketCalendarV1Type> } = {
  specification: {
    type: SchemaType.MultiTicketCalendar,
    version: 1,
    title: 'Новый единый билет',
    description: '',
    categories: [
      {
        id: uuidv4(),
        name: 'Общий',
        components: [],
      },
    ],
  },
};

export const MultiTicketCalendarProductFormModalContainer: React.FC<Props> = ({
  open,
  onCancel,
  onComplete,
  product,
}) => {
  const productGroups = useSelector(productGroupsSelector);
  const dispatch = useDispatch();

  const onSubmit = useCallback(async (values: any, formikHelpers: FormikHelpers<any>) => {
    if (!submitValidate(values.specification, multiTicketCalendarV1Schema, formikHelpers)) {
      try {
        multiTicketCalendarV1Schema.validateSync(values.specification, { abortEarly: false });
      } catch (error) {
        console.log(JSON.stringify(error));
      }

      notification.error({ message: 'Проверьте правильность заполнения формы' });
      return;
    }
    try {
      const productSpecificationDto = produce(values, (draft: { specification: MultiTicketCalendarV1Type }) => {
        // eslint-disable-next-line no-param-reassign
        draft.specification.title = draft.specification.title.trim();
        // eslint-disable-next-line no-param-reassign
        draft.specification.description = draft.specification.description.trim();
        draft.specification.categories.forEach((category) => {
          // eslint-disable-next-line no-param-reassign
          category.name = category.name.trim();
        });
      });
      if (product) {
        await new Promise((resolve, reject) => {
          dispatch(updateProduct(product.id, productSpecificationDto, resolve, reject));
        });
      } else {
        await new Promise((resolve, reject) => {
          dispatch(createProduct(productSpecificationDto, resolve, reject));
        });
      }
      onComplete();
    } catch (error) {
      formikHelpers.setErrors(error as any);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }, [ onComplete, product, dispatch ]);

  return (
    <MultiTicketCalendarProductFormModal
      open={open}
      productGroups={productGroups}
      product={product}
      initialValues={product || defaultProduct}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
};
