import { process } from "../../env";

export function checkProductItem(productItemCoded: any) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/product-items/check`;
  return fetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`,
    },
    body: JSON.stringify({
      data: productItemCoded,
    }),
  })
    .then((response) => response.json());
}

export function consumeProductItem(id: string, productSpecificationId: string) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/product-items/${id}/consume`;
  return fetch(requestURL, {
    method: 'POST',
    body: JSON.stringify({ productSpecificationId }),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`,
    },
  })
    .then((response) => response.json());
}