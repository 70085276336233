import { combineReducers } from 'redux';

import authentication from './authenticationReducer';
import currentUser from './currentUserReducer';
import products from './productsReducer';
import accounts from './accountsReducer';
import inspectors from './ispectorsReducer';
import { productGroups } from './productGroupsReducer';

export default combineReducers({
  authentication,
  currentUser,
  products,
  accounts,
  inspectors,
  productGroups,
});
