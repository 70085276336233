import React, { ElementType, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Role } from '../../../constants/rbac';
import { PrivateRoutes } from '../../../constants/routes';

import { ApplicationLayout } from '../layouts/application-layout';

import ProductsPageContainer from '../../products-page/ProductsPageContainer';
import OrderPageWrapper from '../../orders-page/OrdersPageContainer';
import ScannerPageContainer from '../../scanner-page/ScannerPageContainer';
import { PartnersPageContainer } from '../../partners-page/PartnersPageContainer';
import PurchasesPageWrapper from '../../purchases-page/PurchasesPageContainer';

import { MockPaymentPageContainer } from '../../mock-payment-page';
import { InspectorsPageContainer } from '../../inspectors-page';
import ReportsPageContainer from '../../reports-page/ReportsPageContainer';
import { AccountPageContainer } from '../../account-page/AccountPageContainer';
import { BalancePage } from '../../balance-page/BalancePage';

interface AuthenticatedRoutesDependencies {
  user: any;
  rbacManager: any;
}

const AuthenticatedRoutes = ({ user, rbacManager }: AuthenticatedRoutesDependencies) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [roles, setRoles] = useState({
    isOrganizer: false,
    isAdministrator: false,
    isInspector: false,
    isDistributor: false,
  });

  useEffect(() => {
    rbacManager.checkAccess(user.id, Role.Administrator)
      .then((isAdministrator: boolean) => setRoles((currentRoles) => ({ ...currentRoles, isAdministrator })));
    rbacManager.checkAccess(user.id, Role.Organizer)
      .then((isOrganizer: boolean) => setRoles((currentRoles) => ({ ...currentRoles, isOrganizer })));
    rbacManager.checkAccess(user.id, Role.Distributor)
      .then((isDistributor: boolean) => setRoles((currentRoles) => ({ ...currentRoles, isDistributor })));
    rbacManager.checkAccess(user.id, Role.Inspector)
      .then((isInspector: boolean) => setRoles((currentRoles) => ({ ...currentRoles, isInspector })))
      .then(() => setIsLoaded(true));
  }, []);

  if (!isLoaded) {
    return <div />;
  }

  let DefaultRouteComponent: ElementType = ProductsPageContainer;
  if (roles.isInspector) {
    DefaultRouteComponent = ApplicationLayout;
  }
  if (roles.isDistributor) {
    DefaultRouteComponent = OrderPageWrapper;
  }
  if (roles.isOrganizer) {
    DefaultRouteComponent = ProductsPageContainer;
  }

  return (
    <Routes>
      {roles.isOrganizer && <Route path={PrivateRoutes.Products} element={<ProductsPageContainer />} />}
      {roles.isOrganizer && <Route path={PrivateRoutes.Orders} element={<OrderPageWrapper />} />}
      {roles.isOrganizer && <Route path={PrivateRoutes.Purchases} element={<PurchasesPageWrapper />} />}
      {roles.isOrganizer && (
        <Route
          path={PrivateRoutes.Balance}
        >
          <Route
            path="*"
            element={<BalancePage />}
          />
        </Route>
      )}
      {
        (roles.isOrganizer || roles.isInspector) && (
          <Route path={PrivateRoutes.Scanner} element={<ScannerPageContainer />} />
        )
      }
      {roles.isOrganizer && <Route path={PrivateRoutes.Inspectors} element={<InspectorsPageContainer />} />}
      {roles.isAdministrator && <Route path={PrivateRoutes.Partners} element={<PartnersPageContainer />} />}
      <Route path={PrivateRoutes.PaymentMock} element={<MockPaymentPageContainer />} />
      {
        (roles.isOrganizer || roles.isDistributor) && (
          <>
            <Route path={PrivateRoutes.Reports} element={<ReportsPageContainer />} />
            <Route path={PrivateRoutes.Account} element={<AccountPageContainer />} />
          </>
        )
      }
      <Route path="*" element={<DefaultRouteComponent />} />
    </Routes>
  );
};

export default AuthenticatedRoutes;
