import { Dispatch } from 'react';
import moment from 'moment';
import { produce } from 'immer';

import {
  ProductSummaryListContextState, setProductSummaryListLoading,
  storeListItems, storePagination,
} from './productSummaryListReducer';
import { filterSelector, paginationSelector, sortingSelector, accountIdSelector } from './productSummaryListSelectors';
import { fetchPurchases } from '../../../../sagas/api/orders';

export const getList = async (dispatch: Dispatch<any>, getState: () => ProductSummaryListContextState) => {
  const state = getState();
  const filter = filterSelector(state);
  const sorting = sortingSelector(state);
  const accountId = accountIdSelector(state);
  const pagination = paginationSelector(state);
  dispatch(setProductSummaryListLoading(true));
  const apiFilter = produce(filter, (draft) => {
    if (draft.dates) {
      // eslint-disable-next-line no-param-reassign
      draft.dates[0] = moment(draft.dates?.[0]).startOf('day');
      // eslint-disable-next-line no-param-reassign
      draft.dates[1] = moment(draft.dates?.[1]).endOf('day');
    }
  });

  try {
    const result = await fetchPurchases(accountId, apiFilter, pagination, sorting);
    if (!result) {
      throw new Error('No data');
    }
    dispatch(storePagination({
      ...pagination,
      total: result.total,
    }));
    dispatch(storeListItems(result.multiTicketComponents, result.productItems));
  } finally {
    dispatch(setProductSummaryListLoading(false));
  }
};
