import { process } from "../../env";

export function fetchShops() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/shops`;
  return fetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`
    }
  })
    .then((response) => response.json());
}
