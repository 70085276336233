import { connect } from 'react-redux';

import container from '../../container';

import { productGroupsSelector, productsSelector } from '../../selectors/productsSelectors';
import { currentUserSelector } from '../../selectors/currentUserSelectors';
import { fetchProduct, fetchProducts, updateProduct } from '../../actions/productsActions';
import { deleteProductGroup } from '../../actions/productGroupsActions';

import { ProductsPage, ProductSpecificationInterface } from './ProductsPage';

const mapStateToProps = (state: any) => ({
  rbacManager: container.resolve('rbacManager'),
  products: productsSelector(state),
  productGroups: productGroupsSelector(state),
  user: currentUserSelector(state),
  accountId: currentUserSelector(state).accountId,
});

const mapDispatchToProps = (dispatch: any) => ({
  onLoadProducts: () => {
    return new Promise((resolve, reject) => {
      dispatch(fetchProducts(resolve, reject));
    });
  },
  onLoadProduct: (productSpecificationId: string): Promise<{ product: ProductSpecificationInterface }> => {
    return new Promise((resolve, reject) => {
      dispatch(fetchProduct(productSpecificationId, resolve, reject));
    });
  },
  onUpdateProduct: (productSpecificationId: string, productSpecificationDto: any) => {
    return new Promise((resolve, reject) => {
      dispatch(updateProduct(productSpecificationId, productSpecificationDto, resolve, reject));
    });
  },
  onDeleteProductGroup: (productGroupId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      dispatch(deleteProductGroup(productGroupId, resolve, reject));
      resolve();
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
