import { connect } from 'react-redux';

import { restorePassword, changePassword } from "../../actions/authenticationActions";

import RestorePage from './RestorePage';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  onRestore: (email) => {
    return new Promise((resolve, reject) => {
      dispatch(restorePassword({ email }, resolve, reject));
    });
  },
  onChange: (email, password, passwordRepeat, code) => {
    return new Promise((resolve, reject) => {
      dispatch(changePassword({ email, password, passwordRepeat, code }, resolve, reject));
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RestorePage);
