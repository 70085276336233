/* eslint no-template-curly-in-string: off */

import * as yup from 'yup';
import { InferType } from 'yup';

import { AccountTax } from '../../constants/business';
import { BeneficiaryType, Citizenship, DocumentType, RegistrationType } from '../../constants/tinkoff';

export const accountValidationSchema = yup.object({
  // Данные пользователя
  email: yup.string()
    .email('Неверный почтовый адрес')
    .required('Требуется почта'),
  password: yup.string()
    .required('Требуется пароль'),
  passwordRepeat: yup.string()
    .oneOf([yup.ref('password')], 'Пароль и повтор не совпадают')
    .required('Требуется повтор пароля'),

  // Общая информация об организации

  organizationEmail: yup.string()
    .email('Неверный почтовый адрес')
    .required('Требуется почта'),
  inn: yup.string()
    .matches(/^\d{10,12}$/, 'Неверный ИНН')
    .required('ИНН является обязательным к заполнению.'),
  kpp: yup.string()
    .matches(/(^(\d{9})?$)/, 'Неверный КПП')
    .transform((value) => (value === '' ? undefined : value)),
  ogrn: yup.string()
    .matches(/^((1|5)\d{12})|(3\d{14})$/, 'Неверный ОГРН')
    .required('ОГРН обязателен к заполнению'),
  fullName: yup.string()
    .min(5, 'Минимум 5 символов')
    .required('Введите полное название организации'),
  name: yup.string()
    .required('Введите сокращенное название'),
  billingDescriptor: yup.string()
    .matches(/^[A-Z0-9_]{3,12}$/, 'Неверный формат, разрешены символы [A-Z0-9_]')
    .required('Укажите название'),
  siteUrl: yup.string()
    .url('Некорректный веб адрес')
    .required('Укажите вебсайт'),
  // Данные директора
  ceo: yup.object({
    lastName: yup.string()
      .matches(/^[а-яА-Я -]+$/, 'Введены символы не принадлежащие алфавиту')
      .required('Укажите фамилию директора'),
    firstName: yup.string()
      .matches(/^[а-яА-Я -]+$/, 'Введены символы не принадлежащие алфавиту')
      .required('Укажите имя директора'),
    middleName: yup.string()
      .matches(/^[а-яА-Я -]+$/, 'Введены символы не принадлежащие алфавиту')
      .required('Укажите отчество директора'),
  })
    .required('Укажите главного управляющего компанией'),
  // Адрес
  addresses: yup.array().of(yup.object({
    type: yup.string()
      .oneOf(['legal', 'actual', 'post', 'other'])
      .required('Укажите тип адреса'),
    country: yup.string()
      .oneOf(['RUS'])
      .required('Укажите страну'),
    zip: yup.string()
      .required('Укажите почтовый индекс'),
    city: yup.string()
      .required('Укажите город или населенный пункт'),
    street: yup.string()
      .required('Укажите адрес'),
    description: yup.string(),
  }))
    .min(1)
    .required(),
  // Телефоны
  phones: yup.array().of(yup.object({
    type: yup.string()
      .oneOf(['common', 'fax', 'other'])
      .required('Укажите тип телефона'),
    phone: yup.string()
      .matches(/^\+7\d{8,12}$/, 'Номер должен состоять из символа +7 в начале и цифр')
      .required('Укажите номер телефона'),
    description: yup.string()
      .required('Опишите, что это за телефон'),
  })),
  account: yup.object({
    tax: yup.string().oneOf(Object.values(AccountTax)).required(),
  })
    .required(),
});

// Банковские детали
export const bankAccountValidationSchema = yup.object({
  account: yup.string()
    .matches(/^\d+$/, 'Банковский счет должен состоять из цифр')
    .required('Укажите банковский счет'),
  korAccount: yup.string()
    .matches(/^\d+$/, 'Корреспондентский счет должен состоять из цифр')
    .required('Укажите корреспондентский счет'),
  bik: yup.string()
    .matches(/^0\d+$/, 'БИК должен состоять из цифр')
    .required('Укажите БИК'),
  bankName: yup.string()
    .required('Укажите название банка'),
  details: yup.string()
    .required('Укажите назначение платежа'),
  tax: yup.number()
    .default(0),
});

export const ipBeneficiaryValidationSchema = yup.object({
  type: yup.mixed<BeneficiaryType>()
    .oneOf([BeneficiaryType.IpResident], 'Неверный вид компании')
    .required('Укажите вид компании'),
  firstName: yup.string().required('Укажите имя владельца'),
  middleName: yup.string(),
  lastName: yup.string().required('Укажите фамилию владельца'),
  birthDate: yup.string().required('Укажите день рождения владельа'),
  birthPlace: yup.string(),
  citizenship: yup.string()
    .oneOf([Citizenship.Russia])
    .required('Укажите гражданство'),
  phoneNumber: yup.string(),
  email: yup.string().email(),
  documents: yup.array()
    .of(yup.object({
      type: yup.string()
        .oneOf([DocumentType.Passport])
        .required(),
      serial: yup.string().required('Укажите серию'),
      number: yup.string().required('Укажите номер'),
      date: yup.string().required('Укажите дату'),
      division: yup.string().matches(/^(\d{3}-\d{3})$/).required('Укажите код подразделения'),
    }))
    .min(1)
    .required('Укажите документ удостоверяющий документ'),
  addresses: yup.array()
    .of(yup.object({
      type: yup.string()
        .oneOf([RegistrationType.RegistrationAddress])
        .required(),
      address: yup.string().required('Укажите адрес регистрации'),
    }))
    .min(1)
    .required('Укажите адрес регистрации'),
  registrationDate: yup.string()
    .required('Укажите дату регистрации'),
  inn: yup.string()
    .matches(/^\d{12}$/, 'Неверный ИНН')
    .required('ИНН является обязательным к заполнению.'),
  ogrn: yup.string()
    .matches(/^|(3\d{14})$/, 'Неверный ОГРН'),
});

export const ulBeneficiaryValidationSchema = yup.object({
  type: yup.mixed<BeneficiaryType>()
    .oneOf([BeneficiaryType.UlResident], 'Неверный вид компании')
    .required('Укажите вид компании'),
  // Наименование организации
  name: yup.string(),
  phoneNumber: yup.string(),
  email: yup.string().email(),
  addresses: yup.array().of(yup.object({
    type: yup.string().oneOf([RegistrationType.LegalEntityAddress]).required(),
    address: yup.string().required('Укажите адрес'),
  }))
    .min(1)
    .required(),
  registrationDate: yup.string(),
  // Организационно-правовая форма
  opf: yup.string().oneOf(['ООО']),
  inn: yup.string()
    .matches(/^\d{10}$/, 'Неверный ИНН')
    .required('ИНН является обязательным к заполнению.'),
  ogrn: yup.string()
    .matches(/^((1|5)\d{12})$/, 'Неверный ОГРН'),
});

export type Account = InferType<typeof accountValidationSchema>;
export type BankAccount = InferType<typeof bankAccountValidationSchema>;
export type UlBeneficiary = InferType<typeof ulBeneficiaryValidationSchema>;
export type IpBeneficiary = InferType<typeof ipBeneficiaryValidationSchema>;

export const accountInitialValues: Account = {
  // Данные пользователя
  email: '',
  password: '',
  passwordRepeat: '',
  // Общая информация об организации
  organizationEmail: '',
  inn: '',
  kpp: '',
  ogrn: '',
  fullName: '',
  name: '',
  billingDescriptor: '',
  siteUrl: 'https://',
  // Директор
  ceo: {
    firstName: '',
    lastName: '',
    middleName: '',
  },
  // Адреса
  addresses: [
    {
      type: 'legal',
      country: 'RUS',
      zip: '',
      city: '',
      street: '',
      description: '',
    },
  ],
  // Телефоны
  phones: [
    {
      type: 'common',
      phone: '+7',
      description: 'Основной для клиентов',
    },
    {
      type: 'common',
      phone: '+7',
      description: 'Для контактов ClickPass',
    },
  ],
  account: {
    tax: AccountTax.NONE,
  },
};

export const bankAccountInitialValues: BankAccount = {
  account: '',
  korAccount: '',
  bik: '',
  bankName: '',
  details: 'Перевод средств по Лицензионному Договору по Реестру Операций от ${date}.'
    + ' Лицензионное отчисление ${rub} руб. ${kop} коп., НДС не облагается.'
    + ' ACCOUNT_ID ${accountId}',
  tax: 0,
};

export const ulInitialValues: UlBeneficiary = {
  type: BeneficiaryType.UlResident,
  name: undefined,
  phoneNumber: undefined,
  email: undefined,
  addresses: [{
    type: RegistrationType.LegalEntityAddress,
    address: '',
  }],
  registrationDate: undefined,
  opf: 'ООО',
  inn: '',
  ogrn: undefined,
};

export const ipInitialValues: IpBeneficiary = {
  type: BeneficiaryType.IpResident,
  firstName: '',
  middleName: undefined,
  lastName: '',
  birthDate: '',
  birthPlace: undefined,
  citizenship: Citizenship.Russia,
  phoneNumber: undefined,
  email: undefined,
  documents: [{
    type: DocumentType.Passport,
    serial: '',
    number: '',
    date: '',
    division: '',
  }],
  addresses: [{
    type: RegistrationType.RegistrationAddress,
    address: '',
  }],
  registrationDate: '',
  inn: '',
  ogrn: undefined,
};
