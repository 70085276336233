import React from 'react';
import { Helmet } from 'react-helmet';
import { Button, Modal, Space, Table, Typography } from 'antd';
import PropTypes from 'prop-types';

import './InspectorsPage.scss';

import { ApplicationLayout } from '../application/layouts/application-layout';
import InspectorFormModalContainer from './inspector-form-modal/InspectorFormModalContainer';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

export class InspectorsPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inspectorModalVisible: false,
      inspector: null,
    }
  }

  componentDidMount() {
    this.props.onLoadInspectors();
  }

  getColumns = () => [
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      width: '80px',
      render: (id, record) => {
        return <React.Fragment>
          <Space>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                this.setState({
                  inspectorModalVisible: true,
                  inspector: record,
                });
              }}
            />
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                this.showDeleteConfirm(record.id);
              }}
            />
          </Space>
        </React.Fragment>;
      },
    },
  ];

  showDeleteConfirm(inspectorId) {
    const { onDeleteInspector } = this.props;
    Modal.confirm({
      title: 'Вы точно хотите удалить билетера?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Отмена',
      onOk() {
        return onDeleteInspector(inspectorId);
      },
    });
  }

  render() {
    const { inspectors } = this.props;
    const { inspectorModalVisible, inspector } = this.state;
    return (
      <ApplicationLayout>
        <Helmet>
          <title>Билетеры</title>
        </Helmet>
        <div className="InspectorsPage">
          <div className="InspectorsPage-header">
            <Typography.Title level={3} className="InspectorsPage-header-title">Билетеры</Typography.Title>
            <Space>
              <Button
                className="InspectorsPage-header-addBtn"
                onClick={() => {
                  this.setState({
                    inspectorModalVisible: true,
                    inspector: null,
                  });
                }}
              >
                Добавить билетера
              </Button>
            </Space>
          </div>
          <Table
            columns={this.getColumns()}
            dataSource={inspectors}
            pagination={false}
            rowKey="id"
          />
          {
            inspectorModalVisible &&
            <InspectorFormModalContainer
              visible={true}
              inspector={inspector}
              onCancel={() => {
                this.setState({ inspectorModalVisible: false });
              }}
              onComplete={() => {
                this.setState({ inspectorModalVisible: false });
              }}
            />
          }
        </div>
      </ApplicationLayout>
    )
  }
}

InspectorsPage.propTypes = {
  onLoadInspectors: PropTypes.func.isRequired,
  inspectors: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string,
  })),
}

export default InspectorsPage;