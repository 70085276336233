import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import './MockPaymentPage.scss'

import { OrderStatus, OrderStatusText } from '../../constants/order';

export class MockPaymentPage extends React.PureComponent {
  componentDidMount() {
    const { onGetState, params: { PaymentId } } = this.props;
    onGetState(PaymentId);
  }

  render() {
    const { params: { PaymentId }, onSuccess, onCancel, payment } = this.props;
    return (
      <div className="MockPaymentPage">
        <h2>Страница заказа</h2>
        <div>
          Статус заказа: "{OrderStatusText[payment.Status]}"
        </div>
        {
          payment.Status === OrderStatus.New
          && <Button className="MockPaymentPage-button" onClick={() => {
            onSuccess(PaymentId)
          }}>
            Завершить успешно
          </Button>
        }
        {
          payment.Status === OrderStatus.New
          && <Button className="MockPaymentPage-button" onClick={() => {
            onCancel(PaymentId)
          }}>
            Отменить
          </Button>
        }
      </div>
    );
  }
}

MockPaymentPage.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onGetState: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      PaymentId: PropTypes.string,
    }),
  }),
}

export default MockPaymentPage;
