import React from 'react';

import './ApplicationLayout.scss';

import HeaderContainer from '../../header/HeaderContainer';
import MenuContainer from '../../menu/MenuContainer';

export const ApplicationLayout = ({ children }) => (
  <div className="ApplicationLayout">
    <div className="ApplicationLayout-header">
      <HeaderContainer />
    </div>
    <div className="ApplicationLayout-menu">
      <MenuContainer />
    </div>
    <div className="ApplicationLayout-content">
      {children}
    </div>
  </div>
);

export default ApplicationLayout;
