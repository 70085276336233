import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Button, Space, Table, Typography } from "antd";
import dayjs from "dayjs";

import './ReportsPage.scss';

import ApplicationLayout from "../application/layouts/application-layout/ApplicationLayout";
import { DeleteOutlined, DownloadOutlined, EditOutlined } from "@ant-design/icons";

const formatDate = (date) => dayjs(date).format('DD.MM.YYYY HH:mm');

const REPORT_TYPE_LABEL = {
  DISTRIBUTOR: 'Для Распространителя',
  ORGANIZER: 'Для Организатора'
}

const ReportsPage = ({ onLoadReports, onDownloadReport }) => {
  const [ reports, setReports ] = useState([]);

  useEffect(async () => {
    const reports = await onLoadReports();
    reports.sort((a, b) => {
      if (a.data.year > b.data.year) {
        return -1;
      }
      if (b.data.year > a.data.year) {
        return 1;
      }
      if (a.data.month > b.data.month) {
        return -1;
      }
      if (b.data.month > a.data.month) {
        return 1;
      }
    });
    setReports(reports);
  }, []);


  const columns = [
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      width: '200px',
      render: (created) => (formatDate(created))
    }, {
      title: 'Тип отчета',
      dataIndex: ['type'],
      render: (type) => REPORT_TYPE_LABEL[type]
    }, {
      title: 'Год',
      dataIndex: ['data', 'year']
    }, {
      title: 'Месяц',
      dataIndex: ['data', 'month'],
      render: (month) => {
        return dayjs().month(month - 1).format('MMMM')
      }
    },
    {
      dataIndex: ['data', 'files'],
      width: '80px',
      render: (files) => {
        return <React.Fragment>
          <Space>
            {files.map((file) => {
              return <Button
                id={file.id}
                icon={<DownloadOutlined />}
                onClick={() => {
                  onDownloadReport(file.id, file.name);
                }}
              >
                {mapMimeTypeToExtension(file.mimeType)}
              </Button>
            })}
          </Space>
        </React.Fragment>;
      },
    },
  ];

  return (
    <ApplicationLayout>
      <Helmet>
        <title>Отчеты по расписанию</title>
      </Helmet>
      <div className="ReportsPage">
        <div className="ReportsPage-header">
          <Typography.Title level={3} className="ReportsPage-header-title">Отчеты по расписанию</Typography.Title>
        </div>
        <Table
          columns={columns}
          dataSource={reports}
          pagination={false}
          rowKey="id"
        />
      </div>
    </ApplicationLayout>
  )
}

export default ReportsPage;


export const mapMimeTypeToExtension = (mimeType) => {
  switch (mimeType) {
    case "text/csv":
      return 'CSV';
    case "application/pdf":
      return 'PDF';
    case "application/vnd.ms-excel":
      return 'XLSX';
  }
}
