import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'moment/locale/ru';

import './index.less';

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import "dayjs/locale/ru"
import store from './store';

import ApplicationContainer from './components/application/ApplicationContainer';

import './components/ant/Button.scss';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('ru');

moment.locale('ru');

ReactDOM.render((
  <Provider store={store}>
    <ApplicationContainer />
  </Provider>
), document.getElementById('root'));
