export const AUTHENTICATE = 'AUTHENTICATE';
export const authenticate = () => ({ type: AUTHENTICATE });

export const AUTHENTICATE_STARTED = 'AUTHENTICATE_STARTED';
export const authenticateStarted = () => ({ type: AUTHENTICATE_STARTED });

export const AUTHENTICATE_FINISHED = 'AUTHENTICATE_FINISHED';
export const authenticateFinished = (success = false) => ({
  type: AUTHENTICATE_FINISHED,
  payload: { success }
});

export const SIGN_IN = 'SIGN_IN';
export const signIn = ({ email, password, remember }, resolve, reject) => ({
  type: SIGN_IN,
  payload: {
    email,
    password,
    remember
  },
  meta: {
    resolve,
    reject
  }
});

export const SIGN_IN_STARTED = 'SIGN_IN_STARTED';
export const signInStarted = () => ({ type: SIGN_IN_STARTED });

export const SIGN_IN_FINISHED = 'SIGN_IN_FINISHED';
export const signInFinished = (success = false) => ({
  type: SIGN_IN_FINISHED,
  payload: { success }
});

export const SIGN_OUT = 'SIGN_OUT';
export const signOut = () => ({
  type: SIGN_OUT
});

export const RESTORE_PASSWORD = 'RESTORE_PASSWORD';
export const restorePassword = ({ email }, resolve, reject) => ({
  type: RESTORE_PASSWORD,
  payload: {
    email
  },
  meta: {
    resolve,
    reject
  }
});

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const changePassword = ({ email, password, passwordRepeat, code }, resolve, reject) => ({
  type: CHANGE_PASSWORD,
  payload: {
    email,
    password,
    passwordRepeat,
    code
  },
  meta: {
    resolve,
    reject
  }
});
