import { takeEvery, call } from 'redux-saga/effects';

import { DOWNLOAD_REPORT, FETCH_REPORTS } from "../actions/reportsActions";
import { downloadReport, fetchReports } from "./api";

// Saga workers

export function* fetchReportsWorker(action) {
  const response = yield call(fetchReports);
  if (response.status === 'OK') {
    action?.meta?.resolve(response.payload.reports);
    return response.payload.reports;
  } else if (response.status === 'ERROR') {
    action?.meta?.reject();
    return null;
  }
}

export function* downloadReportWorker(action) {
  const { content, mimeType } = yield call(downloadReport, action.payload.fileId);

  const blob = new Blob([content], mimeType);

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, action.payload.name);
  } else {
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', action.payload.name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}


// Saga watchers

export default function* users() {
  yield takeEvery(FETCH_REPORTS, fetchReportsWorker);
  yield takeEvery(DOWNLOAD_REPORT, downloadReportWorker);
}