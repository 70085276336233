import { process } from "../../env";

export function signIn({ payload: { email, password, remember } }) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/users/sign-in`;
  return fetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      password,
      remember
    })
  })
    .then((response) => response.json());
}

export function signUp(signUpData) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration`;
  return fetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(signUpData)
  })
    .then((response) => response.json());
}

export function restorePassword({ payload: { email }}) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/users/restore-password`;
  return fetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email
    })
  })
    .then((response) => response.json());
}

export function changePassword({ payload: { email, password, passwordRepeat, code }}) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/users/change-password`;
  return fetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      password,
      passwordRepeat,
      code
    })
  })
    .then((response) => response.json());
}

export function fetchCurrentUser() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/users/current`;
  return fetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`
    }
  })
    .then((response) => response.json());
}
