import { notification } from 'antd';
import produce from 'immer';
// eslint-disable-next-line no-undef
export const commonFetch = async (input: RequestInfo, init: RequestInit = {}) => {
  try {
    const fetchInit = produce(init, (draft) => {
      if (draft.headers) {
        // eslint-disable-next-line no-param-reassign
        (draft.headers as any).Authorization = `Bearer ${window.localStorage.token}`;
      } else {
        // eslint-disable-next-line no-param-reassign
        draft.headers = { Authorization: `Bearer ${window.localStorage.token}` };
      }
      // eslint-disable-next-line no-param-reassign
      return draft;
    });
    const result = await fetch(input, fetchInit);
    if (result.status === 500) {
      try {
        const response = await result.json();
        if (response.message) {
          notification.error({ message: 'Ошибка', description: response.message });
        } else {
          notification.error({
            message: 'Внутренняя ошибка сервера', description: 'Пожалуйста, обратитесь в слуюбу технической поддержки',
          });
        }
      } catch (error) {
        notification.error({
          message: 'Внутренняя ошибка сервера', description: 'Пожалуйста, обратитесь в слуюбу технической поддержки',
        });
      }
    }
    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    notification.error({
      message: 'Произошла сетевая ошибка', description: 'Пожалуйста, проверьте свое соединение с интернетом',
    });
    throw error;
  }
};

const encodeParam = (key: string, value: any) => {
  if (Array.isArray(value)) {
    return value.map((valueItem) => `${encodeURIComponent(`${key}[]`)}=${encodeURIComponent(valueItem)}`).join('&');
  }
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
};

export const encodeQueryFilter = (filter: { [key: string]: any }) => {
  const filterKeys = Object.keys(filter);
  if (!filterKeys.length) {
    return '';
  }

  return `?${filterKeys.map((key) => encodeParam(key, filter[key])).join('&')
  }`;
};

enum ResponseStatus {
  OK = 'OK',
  ERROR = 'ERROR',
}

interface SuccessResponse<T> {
  status: ResponseStatus.OK;
  payload: T;
}

interface ErrorResponse {
  status: ResponseStatus.ERROR;
  payload: {
    message: string
  };
}

export type Response<T> = SuccessResponse<T> | ErrorResponse;

export const getResponse = <T>(response: Response<T>) => {
  if (response.status === ResponseStatus.OK) {
    return response?.payload;
  }
  return undefined;
};
