import { connect } from 'react-redux';

import container from '../../../container';

import { currentUserSelector } from "../../../selectors/currentUserSelectors";
import { signOut } from "../../../actions/authenticationActions";

import Header from "./Header";

const mapStateToProps = (state) => ({
  user: currentUserSelector(state),
  rbacManager: container.resolve('rbacManager')
});

const mapDispatchToProps = (dispatch) => ({
  onSignOut: () => {
    dispatch(signOut());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
