import { asValue, asClass, createContainer } from 'awilix';
import { RbacManager  } from "@brainstaff/rbac";
import { RbacInMemoryAdapter  } from "@brainstaff/rbac-in-memory";
import { RbacHttpAdapter  } from "@brainstaff/rbac-http";

import { process } from './env';

import ConsoleLoggerService from "./services/ConsoleLoggerService";

import RbacRuleFactory from "./rbac/RbacRuleFactory";

// Creating IoC container

const container = createContainer();

container.register({
  logger: asClass(ConsoleLoggerService).singleton()
});

container.register({
  rbacHttpConfiguration: asValue({
    baseUrl: process.env.BACKEND_URL + '/api/v1',
    get headers() {
      return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.token}`
      }
    }
  }),
  rbacCacheAdapter: asClass(RbacInMemoryAdapter).singleton(),
  rbacPersistentAdapter: asClass(RbacHttpAdapter).singleton(),
  rbacRuleFactory: asClass(RbacRuleFactory).singleton(),
  rbacManager: asClass(RbacManager).singleton()
});

export default container;
