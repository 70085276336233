import { connect } from 'react-redux';

import container from '../../../container';

import { currentUserSelector } from '../../../selectors/currentUserSelectors';

import Menu from './Menu';

const mapStateToProps = (state: any) => ({
  user: currentUserSelector(state),
  rbacManager: container.resolve('rbacManager'),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
