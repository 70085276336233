import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { currentUserSelector } from '../../selectors/currentUserSelectors';

import OrdersPage from './OrdersPage';
import {
  OrderSummaryListProvider,
  useOrderSummaryListDispatch,
} from './summary-table/context/orderSummaryListContext';
import { getList } from './summary-table/context/orderSumaryListThunks';

export const OrdersPageContainer = () => {
  const dispatch = useOrderSummaryListDispatch();
  return (
    <OrdersPage
      loadOrders={useCallback(async () => (
        dispatch(getList)
      ), [dispatch])}
    />
  );
};

export const OrderPageWrapper = () => {
  const { accountId } = useSelector(currentUserSelector);
  return (
    <OrderSummaryListProvider initState={{ accountId }}>
      <OrdersPageContainer />
    </OrderSummaryListProvider>
  );
};

export default OrderPageWrapper;
