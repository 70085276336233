export const OrderStatus = {
  New: 'NEW',
  Canceled: 'CANCELED',
  Confirmed: 'CONFIRMED',
  Refunded: 'REFUNDED',
  PartialRefunded: 'PARTIAL_REFUNDED',
};

export const OrderStatusText = {
  [OrderStatus.New]: 'Создан',
  [OrderStatus.Canceled]: 'Отменён',
  [OrderStatus.Confirmed]: 'Подтверждён',
  [OrderStatus.PartialRefunded]: 'Возвращен част.',
  [OrderStatus.Refunded]: 'Возвращен полн.',
};