import { Moment } from 'moment';
import { process } from '../../env';
import { getResponse, Response } from './apiHelper';
import { Sorting as SummarySorting } from '../../components/orders-page/summary-table/context/orderSummaryListReducer';
import {
  Sorting as ProductsSorting,
} from '../../components/purchases-page/products-summary/context/productSummaryListReducer';

export interface Order {
  created: string;
  customerEmail: string;
  customerId: string;
  customerName: string;
  customerPhone: string;
  finalPrice: string;
  id: string;
  paymentId: string;
  requests: string;
  status: string;
  statusChanged: string;
}

interface PurchasesSummaryResponse {
  productItems: any[];
  multiTicketComponents: any[];
  total: number;
}

export interface ProductSummaryFilter {
  dates: [Moment | null, Moment | null] | null;
}

export const fetchPurchases = async (accountId: string, filter: ProductSummaryFilter, pagination: {
  pageSize: number,
  current: number,
}, sorting: ProductsSorting) => {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/purchases?accountId=${accountId}`;
  const backendResponse = await fetch(requestURL, {
    method: 'POST',
    body: JSON.stringify({
      filter,
      pagination,
      sorting,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.token}`,
    },
  });
  const response: Response<PurchasesSummaryResponse> = await backendResponse.json();
  return getResponse(response);
};

interface OrderSummaryResponse {
  productItems: any[];
  orders: Order[];
  total: number;
}

export interface OrderSummaryFilter {
  dates: [Moment | null, Moment | null] | null;
}

export const getOrderList = async (accountId: string, filter: OrderSummaryFilter, pagination: {
  pageSize: number,
  current: number,
}, sorting: SummarySorting): Promise<OrderSummaryResponse | undefined> => {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/orders?accountId=${accountId}`;
  const backendResponse = await fetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.token}`,
    },
    body: JSON.stringify({
      filter,
      pagination,
      sorting,
    }),
  });
  const response: Response<OrderSummaryResponse> = await backendResponse.json();
  return getResponse(response);
};
