import Decimal from 'decimal.js';
import dayjs from 'dayjs';

import { SchemaType } from '../../../constants/schema';
import { getDateTimeString } from '../../../utils/categoriesHelper';

function translateOrderStatus(status: string) {
  switch (status) {
    case 'NEW':
      return 'СОЗДАН';
    case 'CONFIRMED':
      return 'ОПЛАЧЕН';
    case 'CANCELED':
      return 'ОТМЕНЕН';
    case 'REFUNDED':
      return 'ВОЗВРАЩЕН';
    case 'REFUNDED_PARTLY':
      return 'ВОЗВРАЩЕН_ЧАСТИЧНО';
    default:
      return '';
  }
}

export function productItemSellersRevenue(accountId: string, productItem: any) {
  if (productItem.specification.type === SchemaType.MultiTicketCalendar) {
    return productItem.metadata.components
      .reduce((revenue: Decimal, componentItem: any) => (
        revenue.plus(productItemSellersRevenue(accountId, componentItem))
      ), new Decimal(0)) / 1;
  }
  return productItem.item.splitPayment
    .filter((item: any) => item.accountId === accountId && item.productSpecificationId === null)
    .reduce((revenue: Decimal, payment: any) => (
      revenue.plus(payment.amount).minus(payment.fee)
    ), new Decimal(0)) / 100;
}

function productItemCategory(productItem: any) {
  if (
    productItem.specification.type === SchemaType.Base
    || productItem.specification.type === SchemaType.BaseCalendar
    || productItem.specification.type === SchemaType.MultiTicketCalendar
  ) {
    return productItem.specification.categories
      .find((category: any) => category.id === productItem.item.categoryId)
      .name;
  }
  if (productItem.specification.type === SchemaType.MultiTicket) {
    return productItem.specification.components[0].categories
      .find((category: any) => category.id === productItem.item.categoryId)
      .name;
  }
  return '';
}

function productItemDateTime(productItem: any) {
  if (productItem.specification.type === SchemaType.BaseCalendar) {
    return getDateTimeString(productItem);
  }
  if (productItem.specification.type === SchemaType.MultiTicketCalendar) {
    return `Дата: ${productItem.item.date}`;
  }
  return '';
}

export const exportCsv = (accountId: string, orders: any[]) => {
  const productItems = orders
    .flatMap((order) => order.productItems.map((productItem: any) => {
      const data = {
        'Код Заказа': order.id,
        'Заказ Дата': dayjs(order.created).format('DD.MM.YYYY HH:mm:ss Z'),
        'Заказ Дата 2': dayjs(order.created).format(),
        'Заказ Статус': translateOrderStatus(order.status),
        'Заказ Смена Статуса': dayjs(order.statusChanged).format('DD.MM.YYYY HH:mm:ss ZZ'),
        'Заказ Смена Статуса 2': dayjs(order.statusChanged).format(),
        'Заказ Цена': order.finalPrice,
        'Код Товара': productItem.productSpecificationId,
        'Клиент Имя': order.customerName,
        'Клиент Телефон': order.customerPhone,
        'Клиент Почта': order.customerEmail,
        'Код Билета': productItem.id,
        'Билет Название': productItem.specification.title,
        'Билет Категория': productItemCategory(productItem),
        'Билет Цена': productItem.item.price,
        'Билет Доход': productItemSellersRevenue(accountId, productItem),
        'Билет Дата Время': productItemDateTime(productItem),
      };
      return data;
    }));

  const header = Object.keys(productItems[0]).join(';');
  const rows = productItems.map((productItem) => Object.values(productItem).join(';')).join('\n');
  const data = `${header}\n${rows}`;
  const blob = new Blob([ '\ufeff', data ], { type: 'text/csv;charset=utf-8;' });

  // @ts-ignore
  if (navigator.msSaveBlob) {
    // @ts-ignore
    navigator.msSaveBlob(blob, 'orders.csv');
  } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', 'Распространитель-Продажи.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
