import React from 'react';
import { notification } from 'antd';

import BeneficiaryUpdateTask from './BeneficiaryUpdateTask';
import { process } from '../../../../env';

type BeneficiaryUpdateTaskContainerProps = {
  workflowId: string;
}

const BeneficiaryUpdateTaskContainer = ({ workflowId }: BeneficiaryUpdateTaskContainerProps) => (
  <BeneficiaryUpdateTask
    workflowId={workflowId}
    onLoad={async () => {
      const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration/${workflowId}`;
      return fetch(requestURL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.token}`,
        },
      })
        .then((response) => response.json());
    }}
    onUpdate={async (data: any) => {
      try {
        const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration/${workflowId}`;
        await fetch(requestURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.localStorage.token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json());
        return true;
      } catch (error: any) {
        notification.error({ message: error.message });
        return false;
      }
    }}
    onApprove={async () => {
      try {
        // eslint-disable-next-line max-len
        const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration/${workflowId}/register-beneficiary`;
        await fetch(requestURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${window.localStorage.token}`,
          },
        })
          .then((response) => response.json());
        return true;
      } catch (error: any) {
        notification.error({ message: error.message });
        return false;
      }
    }}
  />
);

export {
  BeneficiaryUpdateTaskContainer,
};
