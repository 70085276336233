import React, { useCallback, useState } from 'react';

import { findProductSpecifications } from '../../../../../sagas/api';

import { ProductSearchForm } from './ProductSearchForm';

export interface ProductSearchFormContainerProps {
  onSelect: (productSpecifications: any) => void
}

export const ProductSearchFormContainer: React.FC<ProductSearchFormContainerProps> = (props) => {
  const [ searchResults, setSearchResults ] = useState([]);
  const onSearch = useCallback(async (searchQuery: string) => {
    const productSpecificationsResponse = await findProductSpecifications(searchQuery);
    if (productSpecificationsResponse.status === 'OK') {
      setSearchResults(productSpecificationsResponse.payload.productSpecifications);
    }
  }, []);

  return (
    <ProductSearchForm
      productSpecifications={searchResults}
      onSearch={onSearch}
      onSelect={props?.onSelect}
    />
  );
};
