import { process } from "../../env";

export function fetchInspectors() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/inspector`;
  return fetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`
    }
  })
    .then((response) => response.json());
}

export function createInspector(inspector) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/inspector `;
  return fetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`
    },
    body: JSON.stringify(inspector)
  })
    .then((response) => response.json());
}

export function updateInspector(id, inspector) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/inspector/${id}`;
  return fetch(requestURL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`
    },
    body: JSON.stringify(inspector)
  })
    .then((response) => response.json());
}

export function deleteInspector(id) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/inspector/${id}`;
  return fetch(requestURL, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`
    }
  })
    .then((response) => response.json());
}