import React from 'react';
import PropTypes from 'prop-types';

class Authenticator extends React.Component {
  componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  render() {
    const { isAuthenticating, isAuthenticated, children } = this.props;
    if (isAuthenticating) {
      return children[0];
    } if (isAuthenticated) {
      return children[1];
    }
    return children[2];
  }
}

Authenticator.propTypes = {
  isAuthenticating: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.any,
  onMount: PropTypes.func.isRequired
};

export default Authenticator;
