import { takeEvery, call, put } from 'redux-saga/effects';

import { FETCH_CURRENT_USER, setCurrentUser } from "../actions/currentUserActions";
import { fetchCurrentUser } from "./api";

// Saga workers

export function* fetchCurrentUserWorker() {
  const response = yield call(fetchCurrentUser);
  if (response.status === 'OK') {
    yield put(setCurrentUser(response.payload.user));
    return response.payload.user;
  } else if (response.status === 'ERROR') {
    return null;
  }
}

// Saga watchers

export default function* users() {
  yield takeEvery(FETCH_CURRENT_USER, fetchCurrentUserWorker);
}
