import React from 'react';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import ruRU from 'antd/lib/locale/ru_RU';

import './Application.scss';

import AuthenticatorContainer from './authenticator/AuthenticatorContainer';
import NonAuthenticatedRoutes from './routes/NonAuthenticatedRoutes';
import AuthenticatedRoutesContainer from './routes/AuthenticatedRoutesContainer';
import LoadingLayout from './layouts/loading-layout/LoadingLayout';

const Application = () => {
  return (
    <ConfigProvider locale={ruRU}>
      <BrowserRouter>
        <AuthenticatorContainer>
          <LoadingLayout />
          <AuthenticatedRoutesContainer />
          <NonAuthenticatedRoutes />
        </AuthenticatorContainer>
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default Application;
