import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, Space, Typography } from 'antd';
import { BankAccount, bankAccountValidationSchema } from '../form';
import { InputRow } from '../fields/InputRow';

const { Title } = Typography;

interface Props {
  onSubmit: (values: BankAccount, formikBag: FormikHelpers<any>) => void;
  initialValues: BankAccount;
  onBack: (values: BankAccount) => void;
}

export const BankAccountStep = ({ onSubmit, initialValues, onBack }: Props) => (
  <Formik
    initialValues={initialValues}
    validationSchema={bankAccountValidationSchema}
    onSubmit={onSubmit}
  >
    {({ isSubmitting, values }) => (
      <Form>
        <Title level={1}>Заявка на регистрацию</Title>
        <p>На этой странице вы можете заполнить заявку на присоединение к системе ClickPass.</p>
        <table style={{ maxWidth: '700px', width: '100%' }}>
          <tbody>
            <tr>
              <td colSpan={2}>
                <Title level={3}>Банковские реквизиты</Title>
                <p>
                  Укажите ваш банковский счет, с которого вы оплатите 10р. за принятие публичной оферты. На этот
                  счет будут приходить деньги от продажи билетов.
                </p>
              </td>
            </tr>
            <InputRow label="Расчетный счет" name="account" />
            <InputRow label="Корреспондентский счет" name="korAccount" />
            <InputRow label="БИК" name="bik" />
            <InputRow label="Название банка" name="bankName" />
          </tbody>
        </table>
        <Space>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              onBack(values);
            }}
          >
            Назад
          </Button>
          <Button type="primary" htmlType="submit" disabled={isSubmitting}>
            Отправить заявку
          </Button>
        </Space>
      </Form>
    )}
  </Formik>
);
