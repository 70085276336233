import React from 'react';
import { useSelector } from 'react-redux';

import { currentUserSelector } from '../../selectors/currentUserSelectors';

import PurchasesPage from './PurchasesPage';
import {
  ProductSummaryListProvider, useProductSummaryListDispatch,
} from './products-summary/context/productSummaryListContext';
import { useCallback } from 'react';
import { getList } from './products-summary/context/productsSumaryListThunks';

export const PurchasesPageContainer = () => {
  const dispatch = useProductSummaryListDispatch();
  return (
    <PurchasesPage
      loadPurchases={useCallback(async () => (
        dispatch(getList)
      ), [dispatch])}
    />
  );
};

export const PurchasesPageWrapper = () => {
  const { accountId } = useSelector(currentUserSelector);
  return (
    <ProductSummaryListProvider initState={{ accountId }}>
      <PurchasesPageContainer />
    </ProductSummaryListProvider>
  );
};

export default PurchasesPageWrapper;
