import React from 'react';
import { FieldProps } from 'formik';
import {
  Radio as _Radio,
  RadioProps as _RadioProps,
  RadioGroupProps as _RadioGroupProps,
} from 'antd';

import Field from './Field';
import { FormikFieldProps } from './FieldProps';
import { FormItemProps, makeField } from './makeField';

type RadioProps = FieldProps & _RadioProps;
type RadioGroupProps = FieldProps & _RadioGroupProps;

type RadioFieldProps = FormikFieldProps & _RadioProps;
type RadioGroupFieldProps = FormikFieldProps & _RadioGroupProps;

const InternalRadio = React.forwardRef(
  (
    {
      field,
      form,
      meta,
      onChange: _onChange,
      ...restProps
    }: RadioProps,
    ref: React.Ref<HTMLElement>,
  ) => {
    const {
      value, onChange, name,
    } = field;
    return (
      <_Radio
        name={name}
        ref={ref}
        value={value}
        onChange={(event) => {
          onChange(event);
          if (_onChange) {
            _onChange(event);
          }
        }}
        {...restProps}
      />
    );
  },
);

InternalRadio.displayName = 'InternalRadio';

const InternalRadioField = React.forwardRef(
  (
    {
      name,
      validate,
      fast,
      ...restProps
    }: RadioFieldProps,
    ref: React.Ref<HTMLElement>,
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {(fieldProps: any) => (
        <InternalRadio ref={ref} {...fieldProps} {...restProps} />
      )}
    </Field>
  ),
);

InternalRadioField.displayName = 'InternalRadioField';

const InternalRadioGroup = React.forwardRef(
  (
    {
      field,
      form,
      meta,
      onChange: _onChange,
      ...restProps
    }: RadioGroupProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const {
      value, onChange, name,
    } = field;
    return (
      <_Radio.Group
        name={name}
        ref={ref}
        value={value}
        onChange={(event) => {
          onChange(event);
          if (_onChange) {
            _onChange(event);
          }
        }}
        {...restProps}
      />
    );
  },
);

InternalRadioGroup.displayName = 'InternalRadioGroup';

const InternalRadioGroupField = React.forwardRef(
  (
    {
      name,
      validate,
      fast,
      ...restProps
    }: RadioGroupFieldProps,
    ref: React.Ref<HTMLDivElement>,
  ) => (
    <Field name={name} validate={validate} fast={fast}>
      {(fieldProps: any) => (
        <InternalRadioGroup ref={ref} {...fieldProps} {...restProps} />
      )}
    </Field>
  ),
);

InternalRadioGroupField.displayName = 'InternalRadioGroupField';

interface RadioType
  extends React.ForwardRefExoticComponent<RadioProps & React.RefAttributes<HTMLElement>> {
  Group: typeof InternalRadioGroup;
  Button: typeof _Radio.Button;
}

export const Radio = InternalRadio as RadioType;
Radio.Group = InternalRadioGroup;
Radio.Button = _Radio.Button;

interface RadioFieldType
  extends React.ForwardRefExoticComponent<RadioFieldProps & React.RefAttributes<HTMLElement>> {
  Group: typeof InternalRadioGroupField;
  Button: typeof _Radio.Button;
}

export const RadioField = InternalRadioField as RadioFieldType;
RadioField.Group = InternalRadioGroupField;
RadioField.Button = _Radio.Button;

type RadioGroupWrapperComponent =
  React.ForwardRefExoticComponent<FormItemProps & RadioGroupFieldProps & React.RefAttributes<HTMLDivElement>>;

interface RadioWrapperComponent
  extends React.ForwardRefExoticComponent<FormItemProps & RadioFieldProps & React.RefAttributes<HTMLElement>> {
  Button: typeof _Radio.Button;
  Group: RadioGroupWrapperComponent;
}

export const RadioWrapper = makeField<_RadioProps>(Radio) as RadioWrapperComponent;
RadioWrapper.Button = _Radio.Button;
RadioWrapper.Group = makeField<_RadioGroupProps>(Radio.Group) as RadioGroupWrapperComponent;
