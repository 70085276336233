import React, { useCallback } from 'react';
import isEqual from 'lodash.isequal';
import { SummaryTable } from './SummaryTable';
import { useOrderSummaryListDispatch, useOrderSummaryListState } from './context/orderSummaryListContext';
import {
  accountIdSelector,
  listItemSelector,
  listLoadingSelector,
  sortingSelector,
  paginationSelector,
  filterSelector,
} from './context/orderSummaryListSelectors';
import {
  OrderSummaryListContextState,
  storePagination,
  storeSorting,
  storeFilter, initPagination,
} from './context/orderSummaryListReducer';
import { getList } from './context/orderSumaryListThunks';
import { exportCsv } from '../utils/exportCsv';

export const SummaryTableContainer = () => {
  const state = useOrderSummaryListState();
  const dispatch = useOrderSummaryListDispatch();
  const accountId = accountIdSelector(state);
  const orders = listItemSelector(state);
  const filter = filterSelector(state);
  return (
    <SummaryTable
      accountId={accountId}
      orders={orders}
      sorting={sortingSelector(state)}
      loading={listLoadingSelector(state)}
      pagination={paginationSelector(state)}
      filter={filter}
      onSetPagination={useCallback((pagination: OrderSummaryListContextState['pagination']) => {
        dispatch(storePagination(pagination));
      }, [dispatch])}
      onSetSorting={useCallback((sorting) => {
        dispatch(storeSorting(sorting));
      }, [dispatch])}
      onSetFilter={useCallback((newFilter: OrderSummaryListContextState['filter']) => {
        if (!isEqual(newFilter, filter)) {
          dispatch(storeFilter(newFilter));
          dispatch(storePagination(initPagination));
          dispatch(getList);
        }
      }, [dispatch, filter])}
      onFetch={useCallback(() => dispatch(getList), [dispatch])}
      onExportCsv={useCallback(() => {
        exportCsv(accountId, orders);
      }, [accountId, orders])}
    />
  );
};
