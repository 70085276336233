import React from 'react';
import { Typography } from 'antd';
import { InputRow } from '../fields/InputRow';
import { SelectRow } from '../fields/SelectRow';
import { BeneficiaryType, Citizenship } from '../../../../constants/tinkoff';

const { Title } = Typography;

interface Props {
  beneficiaryType: BeneficiaryType;
  onBeneficiaryTypeChange: (value: BeneficiaryType) => void;
}

export const BeneficiaryInfo = ({ onBeneficiaryTypeChange, beneficiaryType }: Props) => {
  const prefix = beneficiaryType === BeneficiaryType.UlResident ? 'ulBeneficiary' : 'ipBeneficiary';
  return (
    <table style={{ maxWidth: '700px', width: '100%' }}>
      <tbody>
        <tr>
          <td colSpan={2}>
            <Title level={3}>Информация о бенифициаре</Title>
            <p>
              Заметка о бенифицаре
            </p>
          </td>
        </tr>
        <SelectRow
          className="BeneficiaryStep-typeSelect"
          label="Тип"
          name={`${prefix}.type`}
          options={[{
            label: 'Юридическое лицо',
            value: BeneficiaryType.UlResident,
          }, {
            label: 'Индивидуальный предприниматель',
            value: BeneficiaryType.IpResident,
          }]}
          onChange={onBeneficiaryTypeChange}
        />
        {
          beneficiaryType === BeneficiaryType.UlResident
          && (
            <>
              <InputRow label="Имя" name={`${prefix}.name`} />
              <InputRow label="Телефон" name={`${prefix}.phoneNumber`} />
              <InputRow label="Электронная почта" name={`${prefix}.email`} />
              <InputRow label="Адрес юридического лица" name={`${prefix}.addresses.0.address`} />
              <SelectRow
                label="Организационно-правовая форма"
                name={`${prefix}.opf`}
                options={[{
                  label: 'ООО',
                  value: 'ООО',
                }]}
              />
              <InputRow label="Дата регистрации" name={`${prefix}.registrationDate`} />
              <InputRow label="ИНН" name={`${prefix}.inn`} />
              <InputRow label="ОГРН" name={`${prefix}.ogrn`} />
            </>
          )
        }
        {
          beneficiaryType === BeneficiaryType.IpResident
          && (
            <>
              <tr>
                <td colSpan={2}><Title level={4}>Информация о владельце ИП</Title></td>
              </tr>
              <InputRow label="Фамилия" name={`${prefix}.lastName`} />
              <InputRow label="Имя" name={`${prefix}.firstName`} />
              <InputRow label="Отчетсво" name={`${prefix}.middleName`} />
              <InputRow label="День рождения" name={`${prefix}.birthDate`} />
              <InputRow label="Место рождения" name={`${prefix}.birthPlace`} />
              <SelectRow
                label="Гражданство"
                name={`${prefix}.citizenship`}
                options={[{
                  label: 'Россия',
                  value: Citizenship.Russia,
                }]}
              />
              <InputRow label="Телефон" name={`${prefix}.phoneNumber`} />
              <InputRow label="Электронная почта" name={`${prefix}.email`} />
              <tr>
                <td colSpan={2}><Title level={4}>Паспортные данные</Title></td>
              </tr>
              <InputRow label="Сервия" name={`${prefix}.documents.0.serial`} />
              <InputRow label="Номер" name={`${prefix}.documents.0.number`} />
              <InputRow label="Дата выдачи" name={`${prefix}.documents.0.date`} />
              <InputRow label="Код подразделения" name={`${prefix}.documents.0.division`} />
              <tr>
                <td colSpan={2}><Title level={4}>Информация об ИП</Title></td>
              </tr>
              <InputRow label="Адрес регистрации" name={`${prefix}.addresses.0.address`} />
              <InputRow label="Дата регистрации" name={`${prefix}.registrationDate`} />
              <InputRow label="ИНН" name={`${prefix}.inn`} />
              <InputRow label="ОГРНИП" name={`${prefix}.ogrn`} />
            </>
          )
        }
      </tbody>
    </table>
  );
};

export default BeneficiaryInfo;
