import React, { useCallback, useState } from 'react';
import { Divider, Drawer, Form, Modal } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import produce from 'immer';

import './MultiTicketCalendarProductFormModal.scss';

import {
  InputWrapper,
  RadioWrapper,
  SelectWrapper,
  TextAreaWrapper,
} from '../../../../utils/form-helpers/antd-formik';

import { ProductSpecificationInterface } from '../../ProductsPage';
import { Label } from './label/Label';
import { ProductSearchFormContainer } from './product-search-form/ProductSearchFormContainer';
import { CategoriesListContainer } from './categories-list/CategoriesListContainer';
import LocationSelect from '../base-calendar-product-form-modal/location-select/LocationSelect';
import Photos from './photos/Photos';

interface MultiTicketCalendarProductFormModalProps {
  initialValues: any
  product: ProductSpecificationInterface | any
  productGroups: any
  open?: boolean
  onSubmit: (values: any, formikBag: FormikHelpers<any>) => Promise<void>
  onCancel: () => any
}

export const MultiTicketCalendarProductFormModal = (
  props: MultiTicketCalendarProductFormModalProps,
) => {
  const { product, initialValues, open, productGroups } = props;
  const { onSubmit, onCancel } = props;
  const title = product ? 'Обновить товар' : 'Добавить товар';
  const okText = product ? 'Обновить' : 'Добавить';

  const [ categoryId, setCategoryId ] = useState('');
  const [ showProductSpecificationSearch, setShowProductSpecificationSearch ] = useState(false);
  const toggleShowSpecificationSearch = useCallback((newCategoryId: string) => {
    setCategoryId(newCategoryId);
    setShowProductSpecificationSearch(!showProductSpecificationSearch);
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
      }) => (
        <Modal
          maskClosable={false}
          width={1150}
          open={open}
          title={title}
          onCancel={onCancel}
          okText={okText}
          onOk={() => handleSubmit()}
          destroyOnClose
        >
          <div className="MultiTicketCalendarProductFormModal">
            <Form layout="vertical">
              <SelectWrapper
                style={{ width: '200px' }}
                name="productGroupId"
                antFieldProps={{ label: <Label title="Группа товара" /> }}
                defaultValue={null}
              >
                <SelectWrapper.Option value={null}>Не выбрана</SelectWrapper.Option>
                {productGroups.map((productGroup: any) => (
                  <SelectWrapper.Option
                    key={productGroup.id}
                    value={productGroup.id}
                  >
                    {productGroup.name}
                  </SelectWrapper.Option>
                ))}
              </SelectWrapper>
              <Divider />
              <InputWrapper
                name="specification.title"
                antFieldProps={{
                  label: (
                    <Label
                      title="Название"
                      description="Короткое название отображается как главный заголовок"
                    />
                  ),
                }}
              />
              <Divider />
              <TextAreaWrapper
                name="specification.description"
                antFieldProps={{
                  label: (
                    <Label
                      title="Описание"
                      description="Расскажите о своей экскурсии, прогулке, мастер-классе или туре"
                    />
                  ),
                }}
                rows={5}
              />

              <Divider />
              <TextAreaWrapper
                name="specification.included"
                antFieldProps={{ label: <Label title="Что включено" /> }}
                rows={3}
              />

              <Divider />

              <TextAreaWrapper
                name="specification.notIncluded"
                antFieldProps={{ label: <Label title="Не входит" /> }}
                rows={3}
              />

              <Divider />

              <LocationSelect
                initLocation={product?.metadata?.startLocation}
                name="specification.startLocationId"
                antFieldProps={{ label: <Label description="Город начала проведения программы" /> }}
              />

              <InputWrapper
                name="specification.startAddress"
                antFieldProps={{
                  label: <Label title="Адрес начала" />,
                }}
              />

              <LocationSelect
                initLocation={product?.metadata?.endLocation}
                name="specification.endLocationId"
                antFieldProps={{ label: <Label description="Город окончания проведения программы" /> }}
              />

              <InputWrapper
                name="specification.endAddress"
                antFieldProps={{
                  label: <Label title="Адрес окончания" />,
                }}
              />

              <Divider />
              <CategoriesListContainer
                categories={values.specification.categories}
                onProductSpecificationDrawerShow={toggleShowSpecificationSearch}
              />

              <Divider />
              <Label title="Сложность программы" />
              <RadioWrapper.Group
                name="specification.difficulty"
                options={[
                  { label: 1, value: 1 },
                  { label: 2, value: 2 },
                  { label: 3, value: 3 },
                  { label: 4, value: 4 },
                  { label: 5, value: 5 },
                ]}
              />

              <Divider />
              <Label title="Фотографии" className="MultiTicketCalendarProductFormModal-photosLabel" />
              <Photos name="specification.photos" />

              {errors?.summary
                && <div className="MultiTicketCalendarProductFormModal-error">{errors.summary as any}</div>}
            </Form>
          </div>
          {
            showProductSpecificationSearch && (
              <Drawer
                title="Поиск продуктов"
                width="80%"
                open
                getContainer={false}
                onClose={() => setShowProductSpecificationSearch(false)}
              >
                <ProductSearchFormContainer
                  onSelect={(productSpecification: any) => {
                    const updatedProductSpecification = produce(values.specification, (draft: any) => {
                      draft.categories
                        .find((category: any) => category.id === categoryId)
                        .components
                        .push({
                          productSpecificationId: productSpecification.id,
                          categoryId: productSpecification.specification.categories[0].id,
                          day: 1,
                          quantity: 1,
                        });
                    });
                    setFieldValue('specification', updatedProductSpecification);
                    setShowProductSpecificationSearch(false);
                  }}
                />
              </Drawer>
            )
          }
        </Modal>
      )}
    </Formik>
  );
};
