import React from 'react';
import { Typography } from 'antd';
import { InputRow } from '../fields/InputRow';

const { Title } = Typography;

interface Props {
  disabled?: boolean;
}

export const BankInfo = ({ disabled }: Props) => (
  <table style={{ maxWidth: '700px', width: '100%' }}>
    <tbody>
      <tr>
        <td colSpan={2}>
          <Title level={3}>Банковские реквизиты</Title>
          <p>
            Укажите ваш банковский счет, с которого вы оплатите 10р. за принятие публичной оферты. На этот
            счет будут приходить деньги от продажи билетов.
          </p>
        </td>
      </tr>
      <InputRow label="Расчетный счет" name="bankAccount.account" disabled={disabled} />
      <InputRow label="Корреспондентский счет" name="bankAccount.korAccount" disabled={disabled} />
      <InputRow label="БИК" name="bankAccount.bik" disabled={disabled} />
      <InputRow label="Название банка" name="bankAccount.bankName" disabled={disabled} />
    </tbody>
  </table>
);
