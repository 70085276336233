import { takeEvery, put, call } from 'redux-saga/effects';

import container from '../container';

import {
  AUTHENTICATE, SIGN_IN, SIGN_OUT,
  RESTORE_PASSWORD, CHANGE_PASSWORD,
  authenticateStarted,
  authenticateFinished,
  signInStarted,
  signInFinished,
} from '../actions/authenticationActions';
import { fetchCurrentUserWorker } from './currentUserSagas';
import { signIn, restorePassword, changePassword } from './api';

// Saga workers

function* authenticateWorker() {
  yield put(authenticateStarted());
  const user = yield call(fetchCurrentUserWorker);
  if (user) {
    const rbacManager = container.resolve('rbacManager');
    yield call([rbacManager, rbacManager.loadCache]);
    yield put(authenticateFinished(true));
  } else {
    yield put(authenticateFinished(false));
  }
}

function* signInWorker(action) {
  yield put(signInStarted());
  const response = yield call(signIn, action);
  if (response.status === 'OK') {
    window.localStorage.token = response.payload.token.token;
    yield put(signInFinished(true));
    yield call(authenticateWorker);
    action.meta.resolve();
  } else if (response.status === 'ERROR') {
    yield put(signInFinished(false));
    action.meta.reject(response.error);
  }
}

function* signOutWorker() {
  window.localStorage.token = undefined;
  window.location.reload();
}

function* restorePasswordWorker(action) {
  const response = yield call(restorePassword, action);
  if (response.status === 'OK') {
    action.meta.resolve(response.payload);
  } else if (response.status === 'ERROR') {
    action.meta.reject(response.error);
  }
}

function* changePasswordWorker(action) {
  const response = yield call(changePassword, action);
  if (response.status === 'OK') {
    action.meta.resolve(response.payload);
  } else if (response.status === 'ERROR') {
    action.meta.reject(response.error);
  }
}

// Saga watchers

export default function* authentication() {
  yield takeEvery(AUTHENTICATE, authenticateWorker);
  yield takeEvery(SIGN_IN, signInWorker);
  yield takeEvery(SIGN_OUT, signOutWorker);
  yield takeEvery(RESTORE_PASSWORD, restorePasswordWorker);
  yield takeEvery(CHANGE_PASSWORD, changePasswordWorker);
}
