import React, { useCallback, useState } from 'react';
import { useParams, useRouteMatch } from "react-router-dom";

import { tinkoffCancel, tinkoffConfirm, tinkoffGetState } from '../../sagas/api';

import MockPaymentPage from './MockPaymentPage';

export const MockPaymentPageContainer = (props) => {
  const [payment, setPayment] = useState({});
  const params = useParams();
  return (
    <MockPaymentPage
      {...props}
      params={params}
      payment={payment}
      onSuccess={useCallback(async (PaymentId) => {
        const payment = await tinkoffConfirm(PaymentId)
        setPayment(payment);

        // Emulate redirect back

        if (payment.Status === 'CONFIRMED') {
          window.location = payment.SuccessURL;
        } else if (payment.Status === 'CANCELED') {
          window.location = payment.FailURL;
        }
      }, [])}
      onCancel={useCallback(async (PaymentId) => {
        const payment = await tinkoffCancel(PaymentId);
        setPayment(payment);

        // Emulate redirect back

        if (payment.Status === 'CONFIRMED') {
          window.location = payment.SuccessURL;
        } else if (payment.Status === 'CANCELED') {
          window.location = payment.FailURL;
        }
      }, [])}
      onGetState={useCallback(async (PaymentId) => {
        const payment = await tinkoffGetState(PaymentId);
        setPayment(payment);

        // Emulate redirect back

        if (payment.Status === 'CONFIRMED') {
          window.location = payment.metadata.SuccessURL;
        } else if (payment.Status === 'CANCELED') {
          window.location = payment.metadata.FailURL;
        }
      }, [])}
    />
  );
};

export default MockPaymentPageContainer;