import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Typography } from 'antd';
import cn from 'classnames';

import { Role } from '../../../constants/rbac';
import { PrivateRoutes } from '../../../constants/routes';

import './Menu.scss';

interface MenuDependencies {
  rbacManager: any;
  user: any;
}

const Menu = ({ rbacManager, user }: MenuDependencies) => {
  const [isAdministrator, setIsAdministrator] = useState(false);
  const [isOrganizer, setIsOrganizer] = useState(false);
  const [isDistributor, setIsDistributor] = useState(false);
  const [isInspector, setIsInspector] = useState(false);

  useEffect(() => {
    let isUnmounted = false;
    rbacManager.checkAccess(user.id, Role.Administrator)
      .then((isAdministratorResponse: boolean) => {
        if (!isUnmounted) {
          setIsAdministrator(isAdministratorResponse);
        }
      });
    rbacManager.checkAccess(user.id, Role.Organizer)
      .then((isOrganizerResponse: boolean) => {
        if (!isUnmounted) {
          setIsOrganizer(isOrganizerResponse);
        }
      });
    rbacManager.checkAccess(user.id, Role.Distributor)
      .then((isDistributorResponse: boolean) => {
        if (!isUnmounted) {
          setIsDistributor(isDistributorResponse);
        }
      });
    rbacManager.checkAccess(user.id, Role.Inspector)
      .then((isInspectorResponse: boolean) => {
        if (!isUnmounted) {
          setIsInspector(isInspectorResponse);
        }
      });
    return () => {
      isUnmounted = true;
    };
  }, [user.id, rbacManager]);

  const location = useLocation();

  const organizerMenu = (
    isOrganizer
      ? (
        <>
          <div className="Menu-title">
            <Typography.Title level={4}>Организатор</Typography.Title>
          </div>
          <Link to="/products">
            <div className={cn({ 'Menu-item': true, 'Menu-item-active': location.pathname === '/products' })}>
              Товары
            </div>
          </Link>
          <Link to="/purchases">
            <div className={cn({ 'Menu-item': true, 'Menu-item-active': location.pathname === '/purchases' })}>
              Продажи
            </div>
          </Link>
          {/*{*/}
          {/*  user.account.tinkoffDistributorShop?.code*/}
          {/*  && user.account.tinkoffShop?.code*/}
          {/*  && user.account.tinkoffDistributorShop?.code !== user.account.tinkoffShop?.code*/}
          {/*  && (*/}
          {/*    <Link to={PrivateRoutes.BalanceInfo}>*/}
          {/*      <div*/}
          {/*        className={cn({*/}
          {/*          'Menu-item': true,*/}
          {/*          'Menu-item-active': new RegExp(`^${PrivateRoutes.Balance}`).test(location.pathname),*/}
          {/*        })}*/}
          {/*      >*/}
          {/*        Баланс*/}
          {/*      </div>*/}
          {/*    </Link>*/}
          {/*  )*/}
          {/*}*/}
        </>
      ) : null
  );

  const distributorMenu = (
    (isDistributor || isOrganizer)
      ? (
        <>
          <div className="Menu-title">
            <Typography.Title level={4}>Распространитель</Typography.Title>
          </div>
          <Link to="/orders">
            <div className={cn({ 'Menu-item': true, 'Menu-item-active': location.pathname === '/orders' })}>
              Продажи
            </div>
          </Link>
        </>
      ) : null
  );

  const settingsMenu = (
    (isAdministrator || isOrganizer || isDistributor)
      ? (
        <>
          <div className="Menu-title">
            <Typography.Title level={4}>Настройки</Typography.Title>
          </div>
          <div>
            {
              (isOrganizer || isDistributor) && (
                <Link to="/inspectors">
                  <div
                    className={cn({
                      'Menu-item': true,
                      'Menu-item-active': location.pathname === '/inspectors',
                    })}
                  >
                    Пользователи
                  </div>
                </Link>
              )
            }
            {
              isAdministrator && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  <Link to={PrivateRoutes.Partners.replace(':tab', 'partners-list')}>
                    <div
                      className={cn({
                        'Menu-item': true,
                        // eslint-disable-next-line max-len
                        'Menu-item-active': new RegExp(PrivateRoutes.Partners.replace(':tab', '[a-z0-9-]+')).test(location.pathname),
                      })}
                    >
                      Партнеры
                    </div>
                  </Link>
                </>
              )
            }
          </div>
        </>
      ) : null
  );

  const documentsMenu = (
    (isDistributor || isOrganizer)
      ? (
        <>
          <div className="Menu-title">
            <Typography.Title level={4}>Документы</Typography.Title>
          </div>
          <Link to="/reports">
            <div className={cn({ 'Menu-item': true, 'Menu-item-active': location.pathname === '/reports' })}>
              Отчеты по расписанию
            </div>
          </Link>
        </>
      ) : null
  );

  const inspectorMenu = (
    (isInspector || isOrganizer)
      ? (
        <>
          <br />
          <Link to="/scanner">
            <div className={cn({ 'Menu-item': true, 'Menu-item-active': location.pathname === '/scanner' })}>
              Сканер
            </div>
          </Link>
          <br />
        </>
      ) : null
  );

  return (
    <div className="Menu">
      {organizerMenu}
      {distributorMenu}
      {settingsMenu}
      {documentsMenu}
      {inspectorMenu}
    </div>
  );
};

export default Menu;
