import { Dispatch } from 'react';
import { signUp as signUpApi } from 'sagas/api/auth';

import {
  SignupContextState,
} from './signupReducer';
import { bankAccountInfoSelector, beneficiaryInfoSelector, mainInfoSelector } from './signupSelectors';
import { BeneficiaryType } from '../../../constants/tinkoff';

export const signUp = async (dispatch: Dispatch<any>, getState: () => SignupContextState) => {
  const state = getState();
  const mainInfo = mainInfoSelector(state);
  const beneficiaryInfo = beneficiaryInfoSelector(state);
  const bankAccountInfo = bankAccountInfoSelector(state);
  const values = {
    ...mainInfo,
    bankAccount: bankAccountInfo,
    ulBeneficiary: beneficiaryInfo.type === BeneficiaryType.UlResident ? beneficiaryInfo : undefined,
    ipBeneficiary: beneficiaryInfo.type === BeneficiaryType.IpResident ? beneficiaryInfo : undefined,
  };

  const response = await signUpApi(values);
  if (response.status === 'OK') {
    if (!response.payload.workflowId) {
      throw new Error('Процесс не создан.');
    }
    return;
  }
  if (response.status === 'ERROR') {
    throw new Error(response.error);
  }
};
