import { connect } from 'react-redux';

import { downloadReport, fetchReports } from "../../actions/reportsActions";

import ReportsPage from "./ReportsPage";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onLoadReports() {
    return new Promise((resolve, reject) => {
      dispatch(fetchReports(resolve, reject));
    })
  },
  onDownloadReport(fileId, name) {
    return new Promise((resolve, reject) => {
      dispatch(downloadReport(fileId, name, resolve, reject));
    })
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
