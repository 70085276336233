import React from 'react';
import * as Yup from 'yup';
import { Modal, InputNumber } from 'antd';
import { Formik, Form, ErrorMessage } from 'formik';
import { Input } from 'formik-antd';

import './InspectorFormModal.scss';

class InspectorFormModal extends React.Component {
  constructor() {
    super();
    this.onFinish = this.onFinish.bind(this);
  }

  async onFinish(values, { setSubmitting, setErrors }) {
    const { inspector, onCreateInspector, onUpdateInspector, onComplete } = this.props;
    try {
      if (inspector) {
        await onUpdateInspector(inspector.id, values);
      } else {
        await onCreateInspector(values);
      }
      onComplete();
    } catch (error) {
      setErrors(error);
      setSubmitting(false);
    }
  }

  createValidationSchema = Yup.object().shape({
    email: Yup.string().required('Требуется почта'),
    password: Yup.string().required('Требуется пароль'),
  });

  updateValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Требуется почта'),
    password: Yup.string(),
  });

  render() {
    const { visible, onCancel } = this.props;
    const { inspector } = this.props;
    const editMode = !!inspector;
    const title = editMode ? 'Обновить билетера' : 'Добавить билетера';
    const okText = editMode ? 'Обновить' : 'Добавить';
    const defaultInspector = {}
    return (
      <Formik
        enableReinitialize={true}
        initialValues={inspector || defaultInspector}
        validationSchema={editMode ? this.updateValidationSchema : this.createValidationSchema}
        onSubmit={this.onFinish}
      >
        {({
            errors,
            handleSubmit,
          }) => (
          <Modal
            open={visible}
            title={title}
            onCancel={onCancel}
            okText={okText}
            onOk={handleSubmit}
            destroyOnClose={true}
          >
            <div className="InspectorFormModal">
              <Form>
                <table width="100%">
                  <tbody>
                  <tr>
                    <td>
                      Email:<br />
                      <Input name="email" />
                      <ErrorMessage name={`email`}>
                        {(msg) => <div className="InspectorFormModal-error">{msg}</div>}
                      </ErrorMessage>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {editMode ? 'Новый пароль' : 'Пароль'}:<br />
                      <Input.Password name="password" />
                      <ErrorMessage name={`password`}>
                        {(msg) => <div className="InspectorFormModal-error">{msg}</div>}
                      </ErrorMessage>
                    </td>
                  </tr>
                  </tbody>
                </table>
                {errors?.summary && <div className="InspectorFormModal-error">{errors.summary}</div>}
              </Form>
            </div>
          </Modal>
        )}
      </Formik>
    );
  }
}

export default InspectorFormModal;
