import { Button, Space, Table, Tag, Typography } from 'antd';
import React from 'react';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';

import { PrivateRoutes } from '../../../constants/routes';

enum WorkflowExecutionStatus {
  WORKFLOW_EXECUTION_STATUS_UNSPECIFIED = 0,
  WORKFLOW_EXECUTION_STATUS_RUNNING = 1,
  WORKFLOW_EXECUTION_STATUS_COMPLETED = 2,
  WORKFLOW_EXECUTION_STATUS_FAILED = 3,
  WORKFLOW_EXECUTION_STATUS_CANCELED = 4,
  WORKFLOW_EXECUTION_STATUS_TERMINATED = 5,
  WORKFLOW_EXECUTION_STATUS_CONTINUED_AS_NEW = 6,
  WORKFLOW_EXECUTION_STATUS_TIMED_OUT = 7
}

class RegistrationTasks extends React.Component<any, any> {
  state = {
    workflows: [],
  };

  async componentDidMount() {
    const { onLoadRegistrationTasks } = this.props;
    const result = await onLoadRegistrationTasks();
    this.setState({ workflows: result.workflows });
  }

  columns() {
    const { navigate } = this.props;
    return [
      {
        title: 'ID',
        dataIndex: ['execution', 'workflowId'],
        width: 120,
        ellipsis: true,
      }, {
        title: 'Статус',
        dataIndex: ['status'],
        width: 120,
        render: (value: WorkflowExecutionStatus) => {
          switch (value) {
            case WorkflowExecutionStatus.WORKFLOW_EXECUTION_STATUS_RUNNING:
              return <Tag color="blue">В процессе</Tag>;
              break;
            case WorkflowExecutionStatus.WORKFLOW_EXECUTION_STATUS_COMPLETED:
              return <Tag color="green">Завершена</Tag>;
              break;
          }
        },
      }, {
        title: 'Название',
        dataIndex: ['type', 'name'],
        render: (value: string) => {
          switch (value) {
            case 'approveRegistrationWorkflowV2':
              return 'Утвердить регистрацию';
              break;
            case 'verifyRegistrationWorkflowV2':
              return 'Верифицировать регистрацию';
              break;
            case 'correctBeneficiaryWorkflowV2':
              return 'Исправить бенефициара';
              break;
          }
        },
      }, {
        dataIndex: 'id',
        width: '80px',
        render: (id: string, record: any) => {
          return <React.Fragment>
            <Space>
              <Button
                icon={
                  record.status === 'WORKFLOW_EXECUTION_STATUS_RUNNING'
                    ? <EditOutlined />
                    : <EyeOutlined />
                }
                onClick={() => {
                  if (record.type.name === 'approveRegistrationWorkflowV2') {
                    navigate(PrivateRoutes.Partners.replace(':tab', `approve-${record.execution.workflowId}`));
                  }
                  if (record.type.name === 'verifyRegistrationWorkflowV2') {
                    navigate(PrivateRoutes.Partners.replace(':tab', `verify-${record.execution.workflowId}`));
                  }
                  if (record.type.name === 'correctBeneficiaryWorkflowV2') {
                    navigate(PrivateRoutes.Partners.replace(
                      ':tab',
                      `beneficiary-update-${record.execution.workflowId}`,
                    ));
                  }
                }}
              />
            </Space>
          </React.Fragment>;
        },
      },
    ];
  }

  render() {
    const { workflows } = this.state;
    return <div>
      <Table
        columns={this.columns()}
        dataSource={workflows}
        pagination={false}
        rowKey={(record) => record.execution.workflowId}
        expandable={{
          expandedRowRender: (record) => (
            <SyntaxHighlighter language="json">
              {JSON.stringify(record, null, 2)}
            </SyntaxHighlighter>
          ),
        }}
        style={{ width: '100%' }}
        // TODO: Косячит переключение в полноэкранный режим
        scroll={{ x: '100%', y: 'calc(100vh - 142px)' }}
      />
    </div>;
  }
}

export default RegistrationTasks;
