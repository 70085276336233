import { process } from "../../env";

export function fetchReports() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/reports`;
  return fetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`
    }
  })
    .then((response) => response.json());
}

export async function downloadReport(reportId) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/reports/${reportId}/download`;
  const response = await fetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`
    }
  })
  const content = await response.blob();
  const mimeType = response.headers["content-type"]

  return { content, mimeType }
}