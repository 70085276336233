import React from 'react';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import { Modal, InputNumber, Checkbox, Select, Button, Divider } from 'antd';
import { Formik, Form, ErrorMessage } from 'formik';
import { Input } from 'formik-antd';

import './BaseProductFormModal.scss';

import { SchemaType } from '../../../../constants/schema';

const UNLIMITED_AMOUNT = -1;

export class BaseProductFormModal extends React.Component {
  constructor() {
    super();
    this.onFinish = this.onFinish.bind(this);
  }

  async onFinish(values, { setSubmitting, setErrors }) {
    const { product, onCreateProduct, onUpdateProduct, onComplete } = this.props;
    try {
      if (product) {
        await onUpdateProduct(product.id, values);
      } else {
        await onCreateProduct(values);
      }
      onComplete();
    } catch (error) {
      setErrors(error);
      setSubmitting(false);
    }
  }

  validationSchema = Yup.object().shape({
    productGroupId: Yup.string().nullable().default(null),
    specification: Yup.object({
      type: Yup.string().default(SchemaType.Base),
      version: Yup.number().default(1),
      title: Yup.string().required('Введите название продукта.'),
      description: Yup.string().required('Введите описание продукта.'),

      categories: Yup.array().min(1, 'Добавьте хотя бы одну категорию').of(
        Yup.object().shape({
          id: Yup.string().required(),
          name: Yup.string().required('Введите название категории.'),
          price: Yup.number().required('Введите цену продукта.'),
        }),
      ),
      amount: Yup.number().required('Введите количество билетов.'),
    }).required(),
  });

  render() {
    const { open, onCancel, productGroups } = this.props;
    const { product } = this.props;
    const title = product ? 'Обновить товар' : 'Добавить товар';
    const okText = product ? 'Обновить' : 'Добавить';
    const defaultProduct = {
      productGroupId: null,
      specification: {
        type: SchemaType.Base,
        version: 1,
        title: 'Новый товар 1',
        description: 'Описание нового товара',
        categories: [{
          id: uuidv4(),
          name: 'Базовый',
          price: 100.0,
        }],
        amount: 100,
      },
    }
    return (
      <Formik
        enableReinitialize={true}
        initialValues={product || defaultProduct}
        validationSchema={this.validationSchema}
        onSubmit={this.onFinish}
      >
        {({
            values,
            errors,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            initialValues,
          }) => {
          return (
            <Modal
              open={open}
              title={title}
              onCancel={onCancel}
              okText={okText}
              onOk={handleSubmit}
              destroyOnClose={true}
            >
              <div className="BaseProductFormModal">
                <Form>
                  <table width="100%">
                    <tbody>
                      <tr>
                        <td>
                          Название:<br />
                          <Input name="specification.title" />
                          <ErrorMessage name={`specification.title`}>
                            {(msg) => <div className="BaseProductFormModal-error">{msg}</div>}
                          </ErrorMessage>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Описание:<br />
                          <Input.TextArea name="specification.description" />
                          <ErrorMessage name={`specification.description`}>
                            {(msg) => <div className="BaseProductFormModal-error">{msg}</div>}
                          </ErrorMessage>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Divider />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Категории:
                          <Button
                            type="link"
                            icon={<PlusOutlined />}
                            onClick={() => {
                              setFieldValue(`specification.categories[${values.specification.categories.length}]`, {
                                id: uuidv4(),
                                name: 'Базовый',
                                price: 100.0,
                              })
                            }}
                          />
                          <br />
                          <table className="BaseProductFormModal-categoryTable">
                            <thead>
                              <tr>
                                <td>Название</td>
                                <td>Цена</td>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                values.specification.categories.map((category, index) => (
                                  <tr key={category.id}>
                                    <td className="BaseProductFormModal-categoryName">
                                      <Input name={`specification.categories[${index}].name`} />
                                      <ErrorMessage name={`specification.categories[${index}].name`}>
                                        {(msg) => <div className="BaseProductFormModal-error">{msg}</div>}
                                      </ErrorMessage>
                                    </td>
                                    <td>
                                      <InputNumber
                                        value={category.price}
                                        onChange={(value) => setFieldValue(`specification.categories[${index}].price`, value)}
                                        onBlur={() => setFieldTouched(`specification.categories[${index}].price`, true)}
                                      />
                                      <ErrorMessage name={`specification.categories[${index}].price`}>
                                        {(msg) => <div className="BaseProductFormModal-error">{msg}</div>}
                                      </ErrorMessage>
                                    </td>
                                    <td className="BaseProductFormModal-deleteCategoryButton">
                                      <Button
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                          values.specification.categories.splice(index, 1);
                                          setFieldValue(`specification.categories`, values.specification.categories);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                          <ErrorMessage name={`specification.categories`}>
                            {(msg) => (
                              typeof msg === 'string' &&
                              <div className="BaseProductFormModal-error">{msg}</div>
                            )}
                          </ErrorMessage>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Divider />
                        </td>
                      </tr>
                      {
                        values.specification.amount !== UNLIMITED_AMOUNT
                        && <tr>
                          <td>
                            Количество:<br />
                            <InputNumber
                              value={values.specification.amount}
                              onChange={(value) => setFieldValue('specification.amount', value)}
                              onBlur={() => setFieldTouched('specification.amount', true)}
                            />
                            <ErrorMessage name={`specification.amount`}>
                              {(msg) => <div className="BaseProductFormModal-error">{msg}</div>}
                            </ErrorMessage>
                          </td>
                        </tr>
                      }
                      <tr>
                        <td>
                          <Checkbox
                            checked={values.specification.amount === UNLIMITED_AMOUNT}
                            onChange={(e) => {
                              const defaultValue = initialValues.specification.amount === UNLIMITED_AMOUNT ? defaultProduct.specification.amount : initialValues.specification.amount;
                              setFieldValue('specification.amount', e.target.checked ? UNLIMITED_AMOUNT : defaultValue)
                            }}
                            onBlur={() => setFieldTouched('specification.amount', true)}
                          >
                            Без ограничений
                          </Checkbox>
                          <ErrorMessage name={`specification.amount`}>
                            {(msg) => <div className="BaseProductFormModal-error">{msg}</div>}
                          </ErrorMessage>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Divider />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Группа:<br />
                          <Select
                            style={{ width: '200px' }}
                            value={values.productGroupId}
                            onChange={(value) => setFieldValue('productGroupId', value)}
                            onBlur={() => setFieldTouched('productGroupId', true)}
                          >
                            <Select.Option value={null}>Не выбрана</Select.Option>
                            {productGroups.map(productGroup => (
                              <Select.Option
                                key={productGroup.id}
                                value={productGroup.id}
                              >
                                {productGroup.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {errors?.summary && <div className="BaseProductFormModal-error">{errors.summary}</div>}
                </Form>
              </div>
            </Modal>
          )
        }}
      </Formik>
    );
  }
}

export default BaseProductFormModal;
