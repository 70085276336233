import { connect } from 'react-redux';

import { authenticate } from '../../../actions/authenticationActions';
import { authenticationStateSelector } from "../../../selectors/authenticationSelectors";

import Authenticator from './Authenticator';

const mapStateToProps = (state) => {
  const { isAuthenticating, isAuthenticated } = authenticationStateSelector(state);
  return {
    isAuthenticating,
    isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => ({
  onMount: () => {
    setTimeout(() => {
      dispatch(authenticate());
    }, 1500);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticator);
