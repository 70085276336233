import Decimal from 'decimal.js';
import dayjs from 'dayjs';

import { SchemaType } from '../../../constants/schema';
import { getDateTimeString } from '../../../utils/categoriesHelper';

function translateProductItemStatus(status) {
  switch (status) {
    case 'NEW':
      return 'СОЗДАН';
    case 'CONFIRMED':
      return 'ОПЛАЧЕН';
    case 'CONSUMED':
      return 'ПОГАШЕН';
    case 'PARTLY_CONSUMED':
      return 'ЧАСТИЧНО_ПОГАШЕН';
    case 'CANCELED':
      return 'ОТМЕНЕН';
    case 'REFUNDED':
      return 'ВОЗВРАЩЕН';
    case 'REFUNDED_MANUALLY':
      return 'ВОЗВРАЩЕН_ВРУЧНУЮ';
    default:
      return '';
  }
}

export const exportCsv = (accountId, purchases, tab) => {
  let productItems;
  if (tab === 'independent') {
    productItems = purchases.productItems
      .map((productItem) => {
        const payment = productItem.item.splitPayment
          .filter((splitPayment) => splitPayment.accountId === accountId && splitPayment.productSpecificationId !== null)
          .reduce((acc, payment) => acc.plus(payment.amount).minus(payment.fee), new Decimal(0)) / 100;
        const data = {
          'Код Товара': productItem.productSpecificationId,
          'Код Билета': productItem.id,
          'Заказ Дата': dayjs(productItem.created).format('DD.MM.YYYY HH:mm:ss Z'),
          'Заказ Дата 2': dayjs(productItem.created).format(),

          'Клиент Имя': productItem.customerName,
          'Клиент Телефон': productItem.customerPhone,
          'Клиент Почта': productItem.customerEmail,

          'Билет Статус': translateProductItemStatus(productItem.status),
          'Билет Название': productItem.specification.title,
          'Билет Категория': '',
          'Билет Цена': productItem.item.price,
          'Билет Доход': 0,
          'Билет Комиссия CP': 0,
          'Билет Дата Время': '',
        };

        if (
          productItem.specification.type === SchemaType.Base
          || productItem.specification.type === SchemaType.BaseCalendar
        ) {
          data['Билет Категория'] = productItem.specification.categories
            .find((c) => c.id === productItem.item.categoryId).name;
        }
        if (productItem.specification.type === SchemaType.BaseCalendar) {
          data['Билет Дата Время'] = getDateTimeString(productItem);
        }
        if (productItem.specification.type === SchemaType.MultiTicket) {
          data['Билет Категория'] = productItem.specification.components[0].categories
            .find((c) => c.id === productItem.item.categoryId).name;
        }

        if (productItem.specification.type !== 'multi-ticket') {
          data['Билет Доход'] = payment;

          let fee = new Decimal(0);
          productItem.item.splitPayment.forEach((splitPayment) => {
            if (splitPayment.accountId === accountId && splitPayment.productSpecificationId !== null) {
              fee = fee.plus(splitPayment.fee);
            }
          });
          data['Билет Комиссия CP'] = fee.toNumber() / 100;
        }

        return data;
      });
  } else if (tab === 'multiticket-components') {
    productItems = purchases.multiTicketComponents
      .flatMap((multiTicket) => multiTicket.item.splitPayment
        .filter((splitPayment) => splitPayment.accountId === accountId && splitPayment.productSpecificationId !== null)
        .map((splitPayment) => ({
          ...multiTicket,
          item: {
            ...multiTicket.item,
            splitPayment: [splitPayment],
          },
        })))
      .map((productItem) => {
        const payment = productItem.item.splitPayment
          .filter((splitPayment) => splitPayment.accountId === accountId && splitPayment.productSpecificationId !== null)
          .reduce((acc, payment) => acc.plus(payment.amount).minus(payment.fee), new Decimal(0)) / 100;
        const data = {
          'Код Товара': productItem.productSpecificationId,
          'Код Билета': productItem.id,
          'Заказ Дата': dayjs(productItem.created).format('DD.MM.YYYY HH:mm:ss Z'),
          'Заказ Дата 2': dayjs(productItem.created).format(),

          'Клиент Имя': productItem.customerName,
          'Клиент Телефон': productItem.customerPhone,
          'Клиент Почта': productItem.customerEmail,

          'Билет Статус': translateProductItemStatus(productItem.status),
          'Билет Название': productItem.specification.components
            ?.find((component) => component.id === productItem.item.splitPayment[0].productSpecificationId)
            .title,
          'Билет Категория': productItem.specification.components
            ?.find((component) => component.id === productItem.item.splitPayment[0].productSpecificationId)
            .categories
            .find((category) => category.id === productItem.item.categoryId)
            .name,
          'Билет Цена': productItem.specification.components
            ?.find((component) => component.id === productItem.item.splitPayment[0].productSpecificationId)
            .categories
            .find((category) => category.id === productItem.item.categoryId)
            .price,
          'Билет Доход': payment,
          'Билет Комиссия CP': 0,
        };

        let fee = new Decimal(0);
        productItem.item.splitPayment.forEach((splitPayment) => {
          if (splitPayment.accountId === accountId && splitPayment.productSpecificationId !== null) {
            fee = fee.plus(splitPayment.fee);
          }
        });
        data['Билет Комиссия CP'] = fee.toNumber() / 100;

        return data;
      });
  }

  const header = Object.keys(productItems[0]).join(';');
  const rows = productItems.map((productItem) => Object.values(productItem).join(';')).join('\n');
  const data = `${header}\n${rows}`;
  const blob = new Blob(['\ufeff', data], { type: 'text/csv;charset=utf-8;' });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, 'orders.csv');
  } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const suffix = tab === 'independent' ? 'Отдельные-товары' : 'Компоненты-единого-билета';
    link.setAttribute('download', `Организатор-Продажи-${suffix}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
