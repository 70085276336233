import React from 'react';
import { notification } from "antd";

import VerifyTask from "./VerifyTask";
import { process } from "../../../../env";

type VerifyTaskContainerProps = {
  workflowId: string
}

const VerifyTaskContainer = (props: VerifyTaskContainerProps) => {
  return (
    <VerifyTask
      workflowId={props.workflowId}
      onLoad={async () => {
        const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration/${props.workflowId}`;
        return fetch(requestURL, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.localStorage.token}`
          }
        })
          .then((response) => response.json());
      }}
      onUpdate={async (data: any) => {
        try {
          const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration/${props.workflowId}`;
          await fetch(requestURL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${window.localStorage.token}`
            },
            body: JSON.stringify(data)
          })
            .then((response) => response.json());
          return true;
        } catch (error: any) {
          notification.error({ message: error.message });
          return false;
        }
      }}
      onVerify={async () => {
        try {
          const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration/${props.workflowId}/verify`;
          await fetch(requestURL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${window.localStorage.token}`
            }
          })
            .then((response) => response.json());
          return true;
        } catch (error: any) {
          notification.error({ message: error.message });
          return false;
        }
      }}
      onDeny={async () => {
        try {
          const requestURL = `${process.env.BACKEND_URL}/api/v1/workflows/partner-registration/${props.workflowId}/deny`;
          fetch(requestURL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${window.localStorage.token}`
            }
          })
            .then((response) => response.json());
          return true;
        } catch (error: any) {
          notification.error({ message: error.message });
          return false;
        }
      }}
    />
  );
};

export {
  VerifyTaskContainer
};