import { takeEvery, call, put } from 'redux-saga/effects';

import {
  CREATE_PRODUCT,
  FETCH_PRODUCTS,
  FETCH_PRODUCT,
  UPDATE_PRODUCT,
  setProducts,
  CHECK_PRODUCT_ITEM,
  CONSUME_PRODUCT_ITEM,
  FETCH_MULTI_TICKET_COMPONENTS,
} from '../actions/productsActions';
import { setProductGroups } from '../actions/productGroupsActions';
import {
  checkProductItem,
  consumeProductItem,
  createProductSpecification,
  fetchMultiTicketComponents, fetchProductSpecificationById, fetchProductGroups,
  fetchOwnProductSpecifications, ResponseInterface,
  updateProductSpecificationById,
} from './api';

// Saga workers

export function* fetchProductsWorker() {
  const groupsResponse: ResponseInterface = yield call(fetchProductGroups);
  if (groupsResponse.status === 'ERROR') {
    return null;
  }
  const productsResponse: ResponseInterface = yield call(fetchOwnProductSpecifications);
  if (productsResponse.status === 'ERROR') {
    return null;
  }
  if (productsResponse.status === 'OK') {
    yield put(setProducts(productsResponse.payload.products));
    yield put(setProductGroups(groupsResponse.payload.productGroups));
    return {
      products: productsResponse.payload.products,
      productGroups: groupsResponse.payload.productGroups,
    };
  }
  return null;
}

export function* fetchMultiTicketComponentsWorker(action: any) {
  const productsResponse: ResponseInterface = yield call(fetchMultiTicketComponents);
  if (productsResponse.status === 'ERROR') {
    action.meta.reject(productsResponse.error);
    return null;
  }
  if (productsResponse.status === 'OK') {
    action.meta.resolve(productsResponse.payload.productSpecifications);
    return productsResponse.payload.productSpecifications;
  }
  return null;
}

export function* fetchProductWorker(action: any) {
  const productResponse: ResponseInterface = yield call(fetchProductSpecificationById, action.payload.productId);
  if (productResponse.status === 'OK') {
    action.meta.resolve({
      product: productResponse.payload.product,
    });
  }
  if (productResponse.status === 'ERROR') {
    action.meta.reject(productResponse.error);
  }
  return null;
}

export function* createProductWorker(action: any) {
  const response: ResponseInterface = yield call(createProductSpecification, action.payload.product);
  if (response.status === 'OK') {
    yield put(setProducts(response.payload.products));
    action.meta.resolve();
    return response.payload.products;
  }
  if (response.status === 'ERROR') {
    action.meta.reject(response.error);
    return null;
  }
  return null;
}

export function* updateProductWorker(action: any) {
  // eslint-disable-next-line max-len
  const response: ResponseInterface = yield call(updateProductSpecificationById, action.payload.id, action.payload.product);
  if (response.status === 'OK') {
    yield put(setProducts(response.payload.products));
    action.meta.resolve();
    return response.payload.products;
  }
  if (response.status === 'ERROR') {
    action.meta.reject(response.error);
    return null;
  }
  return null;
}

export function* checkProductItemWorker(action: any) {
  try {
    const response: ResponseInterface = yield call(checkProductItem, action.payload.productItem);
    if (response.status === 'OK') {
      action.meta.resolve(response.payload.productItem);
      return response.payload;
    }
    if (response.status === 'ERROR') {
      action.meta.reject(response.error);
      return null;
    }
    return null;
  } catch (e) {
    action.meta.reject('Получен неверный ответ от сервера.');
    return null;
  }
}

export function* consumeProductItemWorker(action: any) {
  try {
    const { payload: { productItem, productSpecification } } = action;
    const response: ResponseInterface = yield call(consumeProductItem, productItem.id, productSpecification.id);
    if (response.status === 'OK') {
      action.meta.resolve(response.payload.productItem);
      return response.payload;
    }
    if (response.status === 'ERROR') {
      action.meta.reject(response.error);
      return null;
    }
    return null;
  } catch (e) {
    action.meta.reject('Получен неверный ответ от сервера.');
    return null;
  }
}

// Saga watchers

export default function* users() {
  yield takeEvery(FETCH_PRODUCTS, fetchProductsWorker);
  yield takeEvery(FETCH_PRODUCT, fetchProductWorker);
  yield takeEvery(CREATE_PRODUCT, createProductWorker);
  yield takeEvery(UPDATE_PRODUCT, updateProductWorker);
  yield takeEvery(CHECK_PRODUCT_ITEM, checkProductItemWorker);
  yield takeEvery(CONSUME_PRODUCT_ITEM, consumeProductItemWorker);
  yield takeEvery(FETCH_MULTI_TICKET_COMPONENTS, fetchMultiTicketComponentsWorker);
}
