import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SigninPageContainer from '../../signin-page/SigninPageContainer';
import SignupPageContainerWrapper from '../../signup-page/SignupPageContainer';
import RestorePageContainer from '../../restore-page/RestorePageContainer';
import { MockPaymentPageContainer } from '../../mock-payment-page';
import { PublicRoutes } from '../../../constants/routes';

const NonAuthenticatedRoutes = () => (
  <Routes>
    <Route path={PublicRoutes.SignUp} element={<SignupPageContainerWrapper />} />
    <Route path={PublicRoutes.Restore} element={<RestorePageContainer />} />
    <Route path={PublicRoutes.PaymentMock} element={<MockPaymentPageContainer />} />
    <Route path="*" element={<SigninPageContainer />} />
  </Routes>
);

export default NonAuthenticatedRoutes;
