import { process } from "../../../env";

export function tinkoffConfirm(PaymentId) {
  const requestURL = `${process.env.BACKEND_URL}/mock/tinkoff/v2/Confirm`;
  return fetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`
    },
    body: JSON.stringify({ PaymentId })
  })
    .then((response) => response.json());
}

export function tinkoffCancel(PaymentId) {
  const requestURL = `${process.env.BACKEND_URL}/mock/tinkoff/v2/Cancel`;
  return fetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`
    },
    body: JSON.stringify({ PaymentId })
  })
    .then((response) => response.json());
}

export function tinkoffGetState(PaymentId) {
  const requestURL = `${process.env.BACKEND_URL}/mock/tinkoff/v2/GetState`;
  return fetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`
    },
    body: JSON.stringify({ PaymentId })
  })
    .then((response) => response.json());
}