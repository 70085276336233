import { process } from "../../env";

export function createProductGroup(productGroup: any) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/product-groups`;
  return fetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`
    },
    body: JSON.stringify(productGroup)
  })
    .then((response) => response.json());
}

export function updateProductGroup(id: string, productGroup: any) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/product-groups/${id}`;
  return fetch(requestURL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`
    },
    body: JSON.stringify(productGroup)
  })
    .then((response) => response.json());
}

export function deleteProductGroup(id: string) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/product-groups/${id}`;
  return fetch(requestURL, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`
    }
  })
    .then((response) => response.json());
}

export function fetchProductGroups() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/product-groups`;
  return fetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${window.localStorage.token}`,
    },
  })
    .then((response) => response.json());
}