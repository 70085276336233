import React from 'react';
import { RedoOutlined } from "@ant-design/icons";

import "./LoadingLayout.scss";

const LoadingLayout = () => {
  return (
    <div className="LoadingLayout">
      <RedoOutlined spin style={{fontSize: 48, color: 'rgb(175, 180, 180)'}}/>
    </div>
  )
};

export default LoadingLayout;
