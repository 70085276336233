export * from './accounts';
export * from './auth';

export * from './productGroups';
export * from './productSpecifications';
export * from "./productItems";

export * from './shops';
export * from './mock/tinkoffPayment';
export * from './reports';
export * from './registration';

export interface ResponseInterface {
  status: string;
  payload?: any;
  error?: any;
}