import {
  AUTHENTICATE_STARTED,
  AUTHENTICATE_FINISHED
} from '../actions/authenticationActions';

const initialState = {
  isAuthenticating: true,
  isAuthenticated: false
};

const authentication = (state = initialState, action) => {
  switch (action.type) {

    case AUTHENTICATE_STARTED:
      return {
        ...state,
        isAuthenticating: true,
        isAuthenticated: false
      };
    case AUTHENTICATE_FINISHED:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: action.payload.success
      };

    default:
      return state;
  }
};

export default authentication;
