import React from 'react';

import './NonAuthLayout.scss';

const NonAuthLayout = ({ children }) => {
  return (
    <div className="NonAuthLayout">
      <div className="NonAuthLayout-content">
        {children}
      </div>
    </div>
  )
}

export default NonAuthLayout;
